import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useContext } from 'react';
import { NotificacionContext } from '../context/notificacion_context';
import { services } from '../services/api';
import { GlobalContext } from "../context/global_context";
import LoaderComponent from '../components/admin/LoaderComponent';
import Button from '@mui/material/Button';
import { Box, Modal } from '@mui/material';
import { FaFileAlt } from "react-icons/fa";
import IconUI from '../components/iu/icon_ui';
import pdf from '../assets/pdf/criterios.pdf'




const DashboardUser = () => {

  const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    border: '2px solid #661d40',
    //border: '2px solid #661d40',
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    overflowY: "scroll",
    height: "90%",
    borderRadius: "40px",
  };

  // const pdfPath = process.env.PUBLIC_URL + '/pdf/criterios.pdf';

  const goTo = useNavigate();
  const { showNotification } = useContext(NotificacionContext)
  const [loader, setLoader] = useState({
    loading: true
  });
  const [state, setState] = useState({
    estados: [],
    openDocumento: false
  });

  const { setFiltro, setFiltro2, setFiltroUser, setAcuse, setFiltroLeida, setDashboard } = useContext(GlobalContext)

  setFiltroUser(localStorage.getItem('usuario'))

  const handleCloseDocumento = () => {
    setState({ ...state, openDocumento: false });
  }

  const dashboardStatus = async () => {
    setLoader({ ...loader, loading: true })
    let response = await services({ method: 'GET', service: `peticiones/cantidadPeticionesPorEstatus`, body: null });
    if (response.status === 200) {
      setLoader({ ...loader, loading: false })
      setState({ ...state, estados: response.data.data })
    } else {
      setLoader({ ...loader, loading: false })
      showNotification('error', 'Ups...', 'Ocurrio algo al cargar la información', null, 4);
    }
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      dashboardStatus()
      setAcuse('');
      setFiltro('');
      setFiltro2('');
      setFiltroLeida('');
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {loader.loading &&
        <LoaderComponent />
      }
      <div id="home" className='home'>
        <div className="container text-center ">
          <div className="d-block justify-content-center">
            <div className="d-flex justify-content-between pt-3">
              <h2 className="mt-4 text-center text-large text-primary text-bolder">
                Bienvenido al Sistema Atención Ciudadana
              </h2>

              <div className="d-block justify-content-center text-center">
                <h6 className="ms-2 mt-4 text-end text-large text-primary text-bolder">
                  Usuario: {localStorage.nombre}
                </h6>
                <h6 className="ms-2 mt-4 text-end text-large text-primary text-bolder">
                  Dependencia: {localStorage.dependencia}
                </h6>
              </div>
            </div>

            <div>
              <h3 className="mt-4 text-center text-large text-primary text-bolder">
                N° Peticiones: {state.estados.totales}
              </h3>
            </div>

            <div className="mt-3 mb-3 row row-cols-1 row-cols-md-4 g-4 justify-content-center">

              <div className='col'>
                <div className="card card--rounded cursor-pointer" style={{
                    backgroundColor: '#dc3545'
                }} onClick={() => {
                  setFiltro('NO CORRESPONDE');
                  setDashboard(5)
                  goTo("/peticionesUsuario")
                }}>
                  <div className="card-body">
                    <h4 className="card-title text-bolder text-white mb-2">No Corresponde</h4>
                    <p className=" text-medium  text-bold text-white mb-1">Cantidad:{state.estados.no_corresponde}</p>
                    {/*<p className=" text-small text-bold text-white mb-1">Porcentaje:{state.estados.no_corresponde_porcentaje} </p>*/}
                  </div>
                </div>
              </div>

              <div className='col'>
                <div className="card card--rounded  cursor-pointer" onClick={() => {
                  setFiltro('TURNADA');
                  setFiltroLeida('LEIDA');
                  setDashboard(1)
                  goTo("/peticionesUsuario")
                }}>
                  <div className="card-body">
                    <h4 className="card-title text-bolder text-morado mb-2">Turnada</h4>
                    <p className=" text-medium  text-bold text-morado mb-1">Cantidad:{state.estados.turnada}</p>
                    {/*<p className="text-small text-bold text-morado mb-1">Porcentaje:{state.estados.turnada_porcentaje} </p>*/}
                  </div>
                </div>
              </div>

              <div className='col'>
                <div className="card card--rounded  cursor-pointer" onClick={() => {
                  setFiltroLeida('NO LEIDA');
                  setDashboard(10)
                  goTo("/peticionesUsuario")
                }}>
                  <div className="card-body">
                    <h4 className="card-title text-bolder text-morado mb-2">No Leídas</h4>
                    <p className=" text-medium  text-bold text-morado mb-1">Cantidad:{state.estados.no_leidas}</p>
                    {/*<p className=" text-small text-bold text-morado mb-1">Porcentaje:{state.estados.no_leidas_porcentaje} </p>*/}
                  </div>
                </div>
              </div>

              {/* <div className='col-4'>
                <div className="card card--rounded  cursor-pointer" onClick={() => { setFiltro('ACEPTADA'); goTo("/peticionesUsuario") }}>
                  <div className="card-body">
                    <h4 className="card-title text-bolder text-morado mb-2">Aceptadas</h4>
                    <p className=" text-medium  text-bold text-morado mb-1">Cantidad:{state.estados.aceptada}</p>
                    <p className=" text-bold text-morado mb-1">Porcentaje:{state.estados.aceptada_porcentaje} </p>
                  </div>
                </div>
              </div> */}


            </div>

            <div className="mt-3 mb-3 row row-cols-1 row-cols-md-4 g-4 justify-content-center">

              <div className='col'>
                <div className="card card--rounded cursor-pointer" onClick={() => {
                  setFiltro('EN TRAMITE');
                  setDashboard(8)
                  goTo("/peticionesUsuario")
                }}>
                  <div className="card-body">
                    <h4 className="card-title text-bolder text-morado mb-2">En Trámite</h4>
                    <p className="text-medium  text-bold text-morado mb-1">Cantidad:{state.estados.en_tramite}</p>
                    {/*<p className="text-small text-bold text-morado mb-1">Porcentaje:{state.estados.en_tramite_porcentaje} </p>*/}
                  </div>
                </div>
              </div>

              <div className='col'>
                <div className="card card--rounded cursor-pointer" onClick={() => {
                  /*setFiltro('ATENDIDA POSITIVA');
                  setFiltro2('NO PROCEDENTE');*/
                  setAcuse('SIN ACUSE');
                  setDashboard(11)
                  goTo("/peticionesUsuario")
                }}>
                  <div className="card-body">
                    <h4 className=" text-bolder text-morado mb-2">Atendidas sin Acuse</h4>
                    <p className=" text-medium  text-bold text-morado mb-1 ">Cantidad:{state.estados.atendidas_sin_acuse}</p>
                    {/*<p className=" text-small text-bold text-morado mb-1">Porcentaje:{state.estados.atendidas_sin_acuse_porcentaje} </p>*/}
                  </div>
                </div>
              </div>



              <div className='col'>
                <div className="card card--rounded cursor-pointer" onClick={() => {
                  setFiltro('ATENDIDA POSITIVA');
                  setFiltro2('NO PROCEDENTE');
                  setAcuse('CON ACUSE');
                  setDashboard(12)
                  goTo("/peticionesUsuario")
                }}>
                  <div className="card-body">
                    <h4 className="card-title text-bolder text-morado mb-2">Atendida con Acuse</h4>
                    <p className=" text-medium  text-bold text-morado mb-1">Cantidad:{state.estados.atendida_acuse}</p>
                    {/*<p className="text-small text-bold text-morado mb-1">Porcentaje:{state.estados.atendida_acuse_porcentaje} </p>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end pt-5">
              <div className="d-block justify-content-center pt-5">

                <div className="cursor-pointer fw-bolder" onClick={() => { setState({ ...state, openDocumento: true }) }}> ¡AYUDA!</div>
                <div className=" d-flex pt-2 cursor-pointer fw-bolder " style={{ textDecoration: 'underline' }} onClick={() => { setState({ ...state, openDocumento: true }) }}>
                  <IconUI size={25} >
                    <FaFileAlt className="pe-2" />
                  </IconUI>
                  "CRITERIOS DE ESTADO DE UNA PETICIÓN"
                </div>
                <Modal
                  open={state.openDocumento}
                  onClose={() => handleCloseDocumento()}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className='contenedorModalUrs' sx={style3}>
                    <p className="text-center">DOCUMENTO</p>
                    <iframe style={{ width: "100%", height: "100%" }} title='DOCUMENTO' id="frameAceptacion" src={pdf} className="preview-iframe" ></iframe>
                    <Button sx={{ mt: 2 }} onClick={() => handleCloseDocumento()} >CERRAR</Button>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default DashboardUser;

//import LogoGris from '../assets/img/LogoGobMich-Artboard1.png'
// import LogoGris from "../assets/img/200anios.png";
import Sello from "../assets/img/Logo2.gif";
// import LogoGris from "../assets/img/HORIZONTAL.png";
import LogoGris from "../assets/img/HORIZONTAL.png";
//import Sello from '../assets/img/Logo Anim.gif'

function Footer() {
  return (
    <>
      <footer
        id="footer"
        className="shadow-sm bg-white border-top--rosa p-4 margin-on-search"
      >
        <div className="container">
          <div className="d-md-flex  align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <img className="logoGris" src={LogoGris} alt="logo" />
              <img className="logoSello ms-5" src={Sello} alt="logo" />
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="text-guinda my-2">#HonestidadyTrabajo</div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({children}) => {
    const token = localStorage.getItem('token');
    const rol = localStorage.getItem('rol');

    if (token !== null && rol === 'ADMINISTRADOR') {
        return children
    } else {
        return <Navigate to="/login" replace={true} />
    }
    
}

export default PrivateRoute;
import { Button, FormControl, IconButton, InputAdornment, InputLabel } from '@mui/material'
import React, { useState, useRef, useContext, useEffect } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import imagenLogin from '../assets/img/solicitud.jpg'
import Input from '@mui/material/Input';
import Slide from '@mui/material/Slide';
import { useNavigate } from "react-router-dom";
import { services } from '../services/api';
import LoaderComponent from '../components/admin/LoaderComponent';
import { NotificacionContext } from '../context/notificacion_context';


function Login() {

    const { showNotification } = useContext(NotificacionContext);

    const navigate = useNavigate();

    const [loader, setLoader] = useState(false)

    const [state, setState] = useState({
        usuario: '',
        pass: '',

    })

    const [register, setRegister] = useState(false);
    const containerRef = useRef(null);

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword })
    }


    const permitions = [
        "REGISTRO",
        "BORRAR_ASUNTO",
        "EXPORTACIÓN",
        "RESTCOORDIVA",
        "AUDIENCIA",
        "EXPORTAR_AUDIENCIA",
        "FIRMAR_DOCUMENTOS",
        "SOLO_LECTURA",
        "REVISAR_DOCUMENTOS",
        "GEOLOCALIZACION"

    ]

    const loginHandle = async () => {
        const usuario = state.usuario;
        const password = state.pass;
        setLoader(true)
        setState({ ...state, usuario: state.usuario, password: state.pass })

        if (usuario === '') {
            setLoader(false)
            showNotification('warning', 'Todos los campos son requeridos', 'Ingresa un Email', null, 4);
        } else if (password === '') {
            setLoader(false)
            showNotification('warning', 'Todos los campos son requeridos', 'Ingresa una Contraseña', null, 4);

        } else {
            const login = await services({ method: 'POST', service: `login`, body: { usuario: state.usuario, password: state.pass } })

            if (login.status === 200) {

                setLoader(false)
                localStorage.setItem('token', login.data.token);
                localStorage.setItem('rol', login.data.rol);
                localStorage.setItem('nombre', login.data.nombre);
                localStorage.setItem('dependencia', login.data.dependencia);
                permitions.forEach(item => {
                    if (login.data.hasOwnProperty(item)) {
                        localStorage.setItem(item, login.data[item]);
                    }
                })



                // setLogin(login.data.token)
                if (login.data.rol === 'ADMINISTRADOR') {
                    navigate("/inicio");
                } else if (login.data.rol === 'DEPENDENCIA') {
                    navigate("/inicioDep");
                } else if (login.data.rol === 'USUARIO') {
                    localStorage.setItem('usuario', login.data.usuario);
                    const firmante = localStorage.getItem('FIRMAR_DOCUMENTOS');
                    const audiencia = localStorage.getItem('AUDIENCIA');
                    const lectura = localStorage.getItem('SOLO_LECTURA');
                    const revisorDocumentos = localStorage.getItem('REVISAR_DOCUMENTOS')


                    if (lectura) {
                        navigate("/inicioUser");
                    } else if (firmante || revisorDocumentos || (firmante && audiencia) ) {
                        navigate("/firmaUser");
                    } else if (audiencia) {
                        navigate("/audienciaUser");
                    } else {
                        navigate("/inicioUser");
                    }

                }
            } else {
                setLoader(false)
            }
        }

    }

    const handleState = ({ target }) => {
        const { value, name } = target;
        setState({ ...state, [name]: value })
    }

    useEffect(() => {

        const token = localStorage.getItem('token');
        const rol = localStorage.getItem('rol');

        let isMounted = true
        if (isMounted) {
            if (token !== null) {
                if (rol === 'ADMINISTRADOR') {
                    navigate("/inicio");
                } else if (rol === 'DEPENDENCIA') {
                    navigate("/inicioDep");
                } else if (rol === 'USUARIO') {
                    const firmante = localStorage.getItem('FIRMAR_DOCUMENTOS');
                    const audiencia = localStorage.getItem('AUDIENCIA');
                    const lectura = localStorage.getItem('SOLO_LECTURA');

                    if (lectura) {
                        navigate("/inicioUser");
                    } else if (firmante || (firmante && audiencia)) {
                        navigate("/firmaUser");
                    } else if (audiencia) {
                        navigate("/audienciaUser");
                    } else {
                        navigate("/inicioUser");
                    }

                }
            }
        }

        const keyDownHandler = event => {
            const btn = document.getElementById('btnLog')

            if (event.key === 'Enter') {
                event.preventDefault();
                btn.click()
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
            isMounted = false
        };


        // eslint-disable-next-line
    }, [])


    return (
        <>
            {loader &&
                <LoaderComponent/>
            }
            <div className='card--height--400 d-flex flex-row flex-wrap justify-content-center py-5'>
                <div className="d-flex justify-content-center justify-content-md-end mb-3 mb-md-0">
                    <img src={imagenLogin} alt="imagenLogin" className='imgLogin'/>
                </div>
                <div className="d-flex flex-column justify-content-center cardLogin p-3" ref={containerRef}>
                    {!register && (
                        <Slide direction="right" in={!register} container={containerRef.current}>
                            <div>
                                <form>
                                    <p className='text-guinda text-left text-bolder mt-4'>Sistema de Atención
                                        Ciudadana</p>
                                    <p className='text-guinda text-left w-75'>Registro.</p>
                                    <div className="d-flex flex-column align-items-center">
                                        <FormControl sx={{m: 1, width: '100%'}} variant="standard">
                                            <InputLabel sx={{ml: "3px"}}
                                                        htmlFor="standard-adornment-email">Usuario</InputLabel>
                                            <Input
                                                value={state.usuario}
                                                name='usuario'
                                                onChange={handleState}
                                                id="standard-adornment-email"
                                                type={'text'}
                                                className='text-guinda'
                                            />
                                        </FormControl>
                                        <FormControl sx={{m: 1, width: '100%'}} variant="standard">
                                            <InputLabel sx={{ml: "3px"}}
                                                        htmlFor="component-simple">Contraseña</InputLabel>
                                            <Input
                                                value={state.pass}
                                                onChange={handleState}
                                                name="pass"
                                                id="component-simple"
                                                type={state.showPassword ? 'text' : 'password'}
                                                className='text-guinda'
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                        >
                                                            {state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='mt-3 d-flex flex-column justify-content-center'>
                                        <p className='cursor-pointer d-none'
                                           style={{textAlign: "center", width: '100%'}}
                                           onClick={() => navigate('/forgetPassword')}>¿Se te olvidó la contraseña?</p>
                                        <Button id='btnLog' type='button' onClick={() => loginHandle()}
                                                style={{margin: "15px auto", width: 'fit-content'}} variant='contained'
                                                className='btn-guinda'>Entrar</Button>
                                    </div>
                                    <span className='mt-4 d-none'>¿No tienes cuenta?, <strong
                                        onClick={() => setRegister(true)}
                                        className='text-guinda text-left text-bolder mt-3 cursor-pointer'>Regístrate</strong></span>
                                </form>
                            </div>
                        </Slide>
                    )}
                </div>
            </div>
        </>
    )
}

export default Login

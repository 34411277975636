import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../components/DataTableComponents';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField } from '@mui/material';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';
import { onlyLetter } from '../utils/inputsRules';
import Tooltip from '@mui/material/Tooltip';
import IconUI from '../components/iu/icon_ui';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';


const TipoCargo = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        // border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "auto",
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const [state, setState] = useState({
        cargos: [],
        cargoSeleccionado: {},
        open: false,
        openEliminar: false,

    }
    );

    const [loader, setLoader] = useState({
        loading: true
    });

    const [openModal, setopenModal] = useState(false)

    const { showNotification } = useContext(NotificacionContext)

    const [formularioState, setFormularioState] = useState(
        {
            nombre: ""
        }
    );

    const openmodal = (data) => {
        setState({ ...state, open: true, cargoSeleccionado: data });
    }

    const handleClose = () => {
        setState({
            ...state, open: false
        });
    }

    const openModalEliminar = (data) => {
        setState({ ...state, openEliminar: true, cargoSeleccionado: data });
    }

    const handleCloseEliminar = () => {
        setState({
            ...state, openEliminar: false
        });
    }

    const handleSeleccionadoCargo = (e)=>{
        const objeto = {
            id: state.cargoSeleccionado.id,
            tipo_cargo: e.target.value,
        }

        setState({ ...state, cargoSeleccionado: objeto });

    }
    const columns = [
        {
            name: "tipo_cargo",
            label: "NOMBRE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.cargos[dataIndex].tipo_cargo;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px' }} className="text-decoration-underline d-flex align-items-center" >
                            <Tooltip title="EDITAR TIPO DE CARGO">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openmodal(state.cargos[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                </div>
                            </Tooltip>
                            <Tooltip title="ELIMINAR TIPO DE CARGO">
                                <div style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }} className='cta cta--warning cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openModalEliminar(state.cargos[dataIndex])
                                        }
                                    }>
                                    <IconUI size={'20px'} color={'black'}><AiOutlineDelete /></IconUI>
                                </div>
                            </Tooltip>


                        </div>
                    </>
                )
            }
        },


    ];

    const validarFormulario = () => {
        const nombreError = formularioState.nombre === '';

        setFormularioState({
            ...formularioState, nombreError: nombreError
        });

        return !nombreError

    }

    const crearCargo = async () => {
        setLoader({ loading: true })
        if (validarFormulario()) {
            const cargo = await services({
                method: 'POST', service: 'catTipoCargos/crear', body: {
                    tipo_cargo: formularioState.nombre,
                }
            })
            if (cargo.status === 200) {
                let cargos = await services({ method: 'GET', service: `catTipoCargos/listarCargos`, body: null });
                setFormularioState({ ...formularioState, nombre: '' })
                if (cargos.status === 200) {
                    setopenModal(false);
                    setState({ ...state, cargos: cargos.data.data })
                    showNotification('success', 'Tipo de Cargo', 'Agregado con Éxito', null, 4);
                    setLoader({ loading: false })
                } else {
                    setLoader({ loading: false })
                    setFormularioState({ ...formularioState, nombre: '' })
                    showNotification('error', 'ups... Algo sucedió', 'Error al Actualizar la Tabla de Cargos', null, 4);
                }
            } else {
                setopenModal(false);
                setLoader({ loading: false })
                setFormularioState({ ...formularioState, nombre: '' })
                showNotification('error', 'Ups... Algo sucedió', cargo.data.message, null, 4);
            }
            setLoader({ loading: false })

        } else {

            showNotification('error', 'ups...Error ', 'Por favor llena todos los campos', null, 4);
            setLoader({ loading: false })

        }
    }

    const editarCargo = async () => {
        if (state.cargoSeleccionado.tipo_cargo === "") {
            showNotification('error', 'Ups...', 'Favor de llenar los campos', null, 4);
        } else {
            setLoader({ loading: true })
            const editarCargo = await services({
                method: 'POST', service: `catTipoCargos/editarCargo`, body: {
                    id: state.cargoSeleccionado.id,
                    tipo_cargo: state.cargoSeleccionado.tipo_cargo?.toUpperCase(),
                }
            })
            if (editarCargo.status === 200) {
                setLoader({ loading: true })
                let cargos = await services({ method: 'GET', service: `catTipoCargos/listarCargos`, body: null })
                if (cargos.status === 200) {
                    setState({ ...state, cargos: cargos.data.data, open: false })
                    showNotification('success', 'Tipo de Cargo', 'Editado con Éxito', null, 4);
                    setLoader({ loading: false })
                } else {
                    setLoader({ loading: false })
                    showNotification('error', 'Ups...', 'Error al Editar el Tipo de Cargo', null, 4);
                }
            } else {
                setLoader({ loading: false })
                showNotification('error', 'Ups...', 'Error al Editar el Tipo de Cargo', null, 4);
            }
        }
    }

    const eliminarCargo = async () => {
        setLoader({ loading: true })
        const eliminarCargo = await services({
            method: 'POST', service: `catTipoCargos/eliminarCargo`, body: {
                id: state.cargoSeleccionado.id,
            }
        })
        if (eliminarCargo) {
            setLoader({ loading: true })
            let cargos = await services({ method: 'GET', service: `catTipoCargos/listarCargos`, body: null })
            if (cargos) {
                setState({ ...state, cargos: cargos.data.data, openEliminar: false })
                showNotification('success', 'Tipo de Cargo Eliminado con Éxito', '', null, 4);
                setLoader({ loading: false })
            } else {
                setLoader({ loading: false })
                showNotification('error', 'Ups...', 'Error al eliminar el Tipo de Cargo', null, 4);
            }
        } else {
            setLoader({ loading: false })
            showNotification('error', 'Ups...', 'Error al eliminar el Tipo de Cargo', null, 4);
        }

    }

    const mostrarCargos = async () => {

        let cargos = await services({ method: 'GET', service: `catTipoCargos/listarCargos`, body: null });

        if (cargos) {
            setState({ ...state, cargos: cargos.data.data })
        }
        setLoader({ loading: false })

    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarCargos()
        }

        return () => {
            isMounted = false
        }
         // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>
                {loader.loading &&
                    <LoaderComponent />
                }
                <div className='container'>
                    <Grid sx={{ marginTop: 3 }} container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                        <Grid item >
                            <ColorButton startIcon={<AddCircleOutlineIcon />} onClick={() => { setopenModal(true) }}>
                                Añadir Tipo Cargo
                            </ColorButton>
                        </Grid>
                    </Grid>
                </div>
                <DataTable
                    data={state.cargos}
                    title={`TABLA TIPOS DE CARGO`}
                    columns={columns}
                />

                {/*Modal para crear */}

                <Modal
                    open={openModal}
                    onClose={() => setopenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            AÑADIR TIPO DE CARGO
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label="NOMBRE"
                                    value={formularioState.nombre}
                                    onChange={({ target }) => setFormularioState({ ...formularioState, nombre: target.value.toUpperCase() })}
                                    onKeyPress={(event) => {
                                        onlyLetter(event, 55);
                                    }}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    crearCargo();

                                }}

                                >
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Editar */}

                <Modal
                    open={state.open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            EDITAR TIPO DE CARGO
                        </h3>

                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label="Nombre"
                                    InputLabelProps={{ shrink: true }}
                                    value={state.cargoSeleccionado.tipo_cargo}
                                    onChange={handleSeleccionadoCargo}
                                    onKeyPress={(event) => {
                                        onlyLetter(event, 55);
                                    }}
                                />
                            </Grid>
                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarCargo();

                                }}

                                >
                                    Editar
                                </ColorButton>
                            </Grid>
                        </Grid>




                    </Box>
                </Modal>

                {/*Modal para Eliminar */}

                <Modal
                    open={state.openEliminar}
                    onClose={handleCloseEliminar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style}>
                        <h3 className='TitulosProgramas mb-3'>
                            ¿ESTÁ SEGURO DE ELIMINAR EL TIPO DE CARGO?
                        </h3>

                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <h4>{state.cargoSeleccionado.tipo_cargo}</h4>
                            </Grid>
                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    eliminarCargo();

                                }}

                                >
                                    Eliminar
                                </ColorButton>
                            </Grid>
                        </Grid>




                    </Box>
                </Modal>
            </div>
        </>

    )
}





export default TipoCargo;

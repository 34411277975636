import Axios from "axios";
import Swal from "sweetalert2";

export const token = () => {
    return window.localStorage.getItem('token');
}

export const config = () => {
    let token = window.localStorage.getItem('token');
    return {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    }
}

export const settings = () => {
    return {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }
}


const axios = Axios.create({
    baseURL: process.env.REACT_APP_URLAPI,
});

export const services = ({method, service, body}) => {
   
    switch (method) {
        case "GET":

            let url = `${service}`;

            return axios.get(url, config()).then((r) => {
                return response(r)
            }).catch((err) => {
                return response(err.response);
            });


        case "POST":

            return axios.post(`${service}`, body, config()).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });


        case "PUT":
            return axios.put(`${service}`, body, config()).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });

        case "DELETE":
            return axios.delete(`${service}`, config()).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });

        default:
            break;
    }
   
}




const response = (r) => {
    
    if (r === undefined) {
        return false;
    }

    if (r.status === 401) {   
        if(localStorage.token){
            Swal.fire({
                title: 'No estas autorizado',
                text: 'Por favor, inicie sesion de nuevo.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false,
                reverseButtons:true
    
            }).then((result) => {
                localStorage.clear()
                window.location.href = '/login'
                return false
            })
        } else{
            Swal.fire({
                icon: 'error',
                title: 'credenciales invalidas',
                showConfirmButton: false,
                timer: 1000
              }).then((result) => {
                return false
            })
        }
        
    }
    if (r.status === 404) {   
        return { status: r.status, data: r.data }
    }
    if (r.status === 409) {   
        return { status: r.status, data: r.data }
    }
    if (r.status === 422) {   
        return { status: r.status, data: r.data }
    }
    if (r.status === 423) {   
        return { status: r.status, data: r.data }
    }
    if (r.status === 200) {
        return { status: r.status, data: r.data }
    }

    if (r.status === 201) {
       
        return false
    }



    if (r.status === 500) {
        return { status: r.status, data: r.data }
    }

    if(r.data.status === "Token esta expirado"){
        Swal.fire({
            title: 'Sesión Expirada',
            text: 'Por favor, inicie sesion de nuevo.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        }).then(( result ) => {
            localStorage.clear()
            window.location.href = '/login'
        } )
    }

    return { status: r.status, errors: r.data.error }
}
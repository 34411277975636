import Grid from '@mui/material/Grid';
import logodos from '../assets/img/Teleferico200.png';
//import logodos from '../assets/img/Veda Horizontal.png';
import logoAtencion from '../assets/img/Atención Ciudadana.png';
//import logoAtencion from '../assets/img/Atención Ciudadana.png';
import React from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation } from "react-router-dom";

function HeaderComponent({ onClose }) {

    const location = useLocation();

    return (
        <>
            <div className="py-1 shadow-sm bg-white boderBottom--Rosa">
                <div className="container">
                    <nav className="navbar navbar-light navbar-expand-lg bg-white ">
                        <div className="container-fluid ">
                            <div className='d-flex w-100 '>
                                <Grid container spacing={12}>
                                    <Grid item xs>

                                        <div className='d-flex flex-row align-items-center justify-content-between'>

                                            {
                                                // eslint-disable-next-lin
                                                location.pathname === "/" || location.pathname === "/login" || location.pathname === "/solicitudCiudadano"|| location.pathname === "/solicitudCiudadanoUsuario"|| location.pathname === "/peticionesCiudadano"|| location.pathname === "/peticionesCiudadanoUsuario"|| location.pathname === "/consulta-peticion" || location.pathname === "/crear-peticion" || location.pathname === "/aciudadana"  ? <></> :
                                                    <div className='justify-content-center align-self-center pill-small' style={{
                                                        marginRight: '10px',
                                                    }} onClick={() => { onClose() }}>
                                                        <div>
                                                            <GiHamburgerMenu />
                                                        </div>
                                                    </div>
                                            }


                                            <div className='d-flex justify-content-start align-items-center'>
                                                <img className="logo-header px-2" src={logodos} alt="logo" />
                                                <p className='Titulo_atencion text-guinda ms-2 mt-5' >Atención Ciudadana</p>
                                            </div>
                                            {
                                                // eslint-disable-next-lin
                                                (location.pathname !== "/" && location.pathname !== "/login") &&
                                                <div className='ms-2  text-guinda'></div>
                                            }
                                            <div className='d-flex flex-row justify-content-end'>
                                                <img className="logo-header2" src={logoAtencion} alt="logo" />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default HeaderComponent;

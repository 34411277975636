import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DataTable from '../components/DataTableComponents';
import IconUI from '../components/iu/icon_ui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField, MenuItem, Menu, Link, Autocomplete } from '@mui/material';
import { AiOutlineSelect } from 'react-icons/ai';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';
import { BsCircleFill, BsCheck2Circle } from "react-icons/bs";
import { AiOutlineDelete } from 'react-icons/ai';
import { IoMdArrowRoundBack } from "react-icons/io";
import moment from 'moment/moment';
import { GlobalContext } from '../context/global_context';



const PeticionesCiudadano = () => {
    

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 600,
        borderRadius: "40px",
    };
    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 'auto',
        borderRadius: "40px",
    };
    const style3 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "90%",
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const goTo = useNavigate();

    const { peticion, setPeticion } = useContext(GlobalContext)

    const { showNotification } = useContext(NotificacionContext)

    const { id } = useParams();

    // state del componente
    const [state, setState] = useState({
        peticiones: [],
        dependencias: [],
        tiposPeticion: [],
        detalles: [],
        adjuntos: [],
        descripcion: '',
        respuesta: '',
        openPeticion: false,
        openDetalles: false,
        openDescripcion: false,
        openRespuesta: false,
        openImprimir: false,
        openAdjuntos: false,
        openDocumento: false,
        openEliminar: false,
        peticionSeleccionada: {},
        documento: null,
        urlFrame: ''
    });

    //modificar peticion

    const [errors, setErrors] = useState({});

    const openModalPeticion = () => {
        setState({ ...state, openPeticion: true, peticionSeleccionada: selectedItemId });
        handleMenuClose()
    }

    const openModalEliminar = () => {
        setState({ ...state, openEliminar: true, peticionSeleccionada: selectedItemId });
        handleMenuClose()
    }

    const openModalDetalles = async () => {
        setLoader({ ...loader, loading: true })
        let detalles = await services({ method: 'POST', service: `peticiones/mostrarPeticion`, body: { id: selectedItemId.id } });
        if (detalles.status === 200) {
            setLoader({ ...loader, loading: false })
            setState({ ...state, detalles: detalles.data.data, openDetalles: true });
            handleMenuClose()
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'ups... Algo sucedió', 'Error al obtener información', null, 4);
            handleMenuClose()
        }

    }

    const openModalDescripcion = (tipo, data) => {
        setState({ ...state, openDescripcion: true, descripcion: tipo + " " + data });
    }

    const openModalImprimir = () => {
        setState({ ...state, openImprimir: true, peticionSeleccionada: selectedItemId.id });
        handleMenuClose()
    }

    const openModalAdjuntos = async () => {
        setLoader({ ...loader, loading: true })
        let listaAdjuntos = await services({ method: 'POST', service: `documentos/listarAdjuntos`, body: { id: selectedItemId.id } });
        if (listaAdjuntos.status === 200) {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openAdjuntos: true, adjuntos: listaAdjuntos.data.data });
            handleMenuClose()
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...Algo Sucedió', 'No se lograron cargar los archivos adjuntos', null, 4);
        }
    }

    const handleTipoPeticiones = (e) => {
        setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, tipo_peticion_id: e ? e.id : null } })
    }

    const handleDependencias = (e) => {
        setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, dependencia_id: e ? e.id : null } })
    }

    const handlePrioridad = (e) => {
        setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, atencion_inmediata: e.target.value } })
    }

    const handleStatusPeticion = (e) => {
        setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, estatus_peticion_id: e.target.value, respuesta: '' } })
    }

    const handleDocumento = (e) => {
        setState({ ...state, documento: e.target.value })
    }

    //state del loading
    const [loader, setLoader] = useState({
        loading: true
    });

    const handleClosePeticion = () => {
        setState({ ...state, openPeticion: false });
        setErrors({});
    }

    const handleCloseEliminar = () => {
        setState({ ...state, openEliminar: false });
    }

    const handleCloseDetalles = () => {
        setState({ ...state, openDetalles: false });
    }

    const handleCloseDescripcion = () => {
        setState({ ...state, openDescripcion: false });
    }

    const handleCloseImprimir = () => {
        setState({ ...state, openImprimir: false });
    }

    const handleCloseDocumento = () => {
        setState({ ...state, openDocumento: false });
    }

    const handleCloseAdjuntos = () => {
        setState({ ...state, openAdjuntos: false });
        setSelectedFile(null)
    }

    const handleAcuse = async (data) => {
        setLoader({ ...loader, loading: true })
        let response = await services({ method: 'POST', service: `peticiones/generarAcuse`, body: { id: data } });
        if (response.status === 200) {
            let peticiones = await services({ method: 'POST', service: `peticiones/peticionesCiudadano`, body: { id: id } });

            if (peticiones.status === 200) {
                setState({ ...state, peticiones: peticiones.data.data })
                setLoader({ ...loader, loading: false })
                showNotification('success', 'Éxito', response.data.message, null, 4);
                handleMenuClose()
            } else {
                setLoader({ ...loader, loading: false })
                showNotification('error', 'ups... Algo sucedió', 'Error al obtener información', null, 4);
                handleMenuClose()
            }
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'ups... Algo sucedió', response.data.message, null, 4);
            handleMenuClose()
        }

    }

    const handleVerAcuse = (data) => {
        setState({ ...state, peticionSeleccionada: data });
        descargarDocumentos(5);
        handleMenuClose();
    }

    // manejo de archivos
    const [selectedFile, setSelectedFile] = useState(null);

    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        let file = event.target.files[0];
        setSelectedFile(file);
    };

    // manejo de archivos

    //Botón de acciones

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const handleMenuClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setSelectedItemId(data);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    //Boton de acciones


    //columnas y lógica de la tabla
    const columns = [
        {
            name: "semaforo",
            label: " ",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let semaforo = state.peticiones[dataIndex].semaforo;
                    if (semaforo === 'ATENDIDA') {
                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'green'}><BsCheck2Circle /></IconUI>
                        </div>)
                    } else if (semaforo === 'VERDE') {

                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'green'}><BsCircleFill /></IconUI>
                        </div>)
                    } else if (semaforo === 'AMARILLO') {
                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'yellow'}><BsCircleFill /></IconUI>
                        </div>)
                    } else {
                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'red'}><BsCircleFill /></IconUI>
                        </div>)
                    }
                }
            }
        },
        {
            name: "acuse",
            label: "ACUSE",
            options: {
                filter: true,
                sort: true,
                display: false,
                customBodyRenderLite: (dataIndex) => {
                    let acuse = state.peticiones[dataIndex].acuse;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>
                        {acuse}
                    </div>)
                }
            }
        },
        {
            name: "folio",
            label: "FOLIO",
            options: {
                filter: true,
                sort: true,
                display: true,
                filterList: peticion !== '' ? [peticion] : null,
                customFilterListOptions: { render: v => v ? `${v}` : '' },
                customBodyRenderLite: (dataIndex) => {
                    let folio = state.peticiones[dataIndex].folio;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>
                        {folio}
                    </div>)
                }
            }
        },
        {
            name: "fecha_creacion",
            label: "FECHA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let alta = moment(state.peticiones[dataIndex].fecha_creacion, 'DD-MM-YYYY');
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}> {alta.format('DD-MM-YYYY')} </div>)
                }
            }
        },
        {
            name: "ciudadano",
            label: "NOMBRE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.peticiones[dataIndex].ciudadano;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "municipio",
            label: "MUNICIPIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let municipio = state.peticiones[dataIndex].municipio;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{municipio}</div>)
                }
            }
        },
        {
            name: "peticion",
            label: "PETICIÓN",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let descripcion = state.peticiones[dataIndex].peticion;
                    let tipo = state.peticiones[dataIndex].tipo_peticion;

                    return (
                        <div style={{ height: '60px' }} className="d-flex align-items-center cursor-pointer " onClick={() => {
                            openModalDescripcion(state.peticiones[dataIndex].tipo_peticion, state.peticiones[dataIndex].peticion);

                        }}>
                            {tipo? tipo + " " + descripcion.substring(0, 30): descripcion.substring(0, 30)} ...
                        </div>
                    )
                }
            }
        },
        {
            name: "dependencia",
            label: "DEPENDENCIA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let dependencia = state.peticiones[dataIndex].dependencia;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{dependencia}</div>)
                }
            }
        },
        {
            name: "tipo_gobierno",
            label: "TIPO DE DEPENDENCIA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let dependencia = state.peticiones[dataIndex].tipo_gobierno;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{dependencia}</div>)
                }
            }
        },
        {
            name: "estatus_peticion",
            label: "ESTADO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let estado = state.peticiones[dataIndex].estatus_peticion;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{estado}</div>)
                }
            }
        },
        {
            name: "respuesta",
            label: "RESPUESTA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let respuesta = state.peticiones[dataIndex].respuesta;
                    if (respuesta !== null) {
                        return (
                            <div style={{ height: '60px' }} className="d-flex align-items-center cursor-pointer" onClick={() => {
                                openModalDescripcion(state.peticiones[dataIndex].respuesta, "");
                            }} >
                                {respuesta.substring(0, 30)}
                                ...
                            </div>
                        )
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center justify-content-center">-</div>)
                    }
                }
            }
        },
        {
            name: "dias_restantes",
            label: "DIAS RESTANTES",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let dias = state.peticiones[dataIndex].dias_restantes;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{dias}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <Button onClick={(event) => handleMenuClick(event, state.peticiones[dataIndex])}>
                                <IconUI size={'20px'} color={'black'}><AiOutlineSelect /></IconUI>
                            </Button>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                {(selectedItemId?.estatus_peticion_id === 4 || selectedItemId?.estatus_peticion_id === 6 || selectedItemId?.estatus_peticion_id === 9) && selectedItemId?.acuse === "SIN ACUSE" ?
                                    <MenuItem onClick={() => { handleAcuse(selectedItemId.id) }}>GENERAR ACUSE</MenuItem>
                                    :
                                    <div></div>
                                }
                                <MenuItem onClick={openModalImprimir}>IMPRIMIR</MenuItem>
                                <MenuItem onClick={openModalPeticion}>EDITAR</MenuItem>
                                <MenuItem onClick={openModalAdjuntos}>ADJUNTOS</MenuItem>
                                <MenuItem onClick={handleVerAcuse}>ACUSES</MenuItem>
                                <MenuItem onClick={openModalDetalles}>DETALLES</MenuItem>
                                <MenuItem onClick={openModalEliminar}>ELIMINAR</MenuItem>
                            </Menu>

                        </>
                    )
                }
            }
        },
    ];


    //Peticiones
    const mostrarPeticiones = async () => {
        setLoader({ ...loader, loading: true })
        let peticiones = await services({ method: 'POST', service: `peticiones/peticionesCiudadano`, body: { id: id } });
        const tiposPeticion = await services({ method: "GET", service: "catTipoPeticiones/listarPeticiones", body: null });
        const dependencias = await services({ method: "GET", service: "dependencias/listar", body: null });

        if (peticiones.status === 200 && tiposPeticion.status === 200 && dependencias.status === 200) {
            setLoader({ ...loader, loading: false })
            setState({ ...state, peticiones: peticiones.data.data, tiposPeticion: tiposPeticion.data.data, dependencias: dependencias.data.data })
        } else {
            setLoader({ ...loader, loading: false })
        }

    }

    const service = {
        1: 'documentos/oficioAyuntamiento',
        2: 'documentos/oficioDependenciaEstatal',
        3: 'documentos/oficioDependenciaFederal',
        4: 'documentos/oficioOrganismosAutonomos',
        5: 'documentos/acusePeticion',
        6: 'documentos/respuestaPeticion',
    }

    const descargarDocumentos = async (type) => {
        setLoader({ ...loader, loading: true })
        const documento = service[type]
        let response = await services({ method: 'POST', service: `${documento}`, body: { id: selectedItemId.id, } });
        if (response.status === 200) {
            let peticiones = await services({ method: 'POST', service: `peticiones/peticionesCiudadano`, body: { id: id } });
            if (peticiones.status === 200) {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openDocumento: true, openImprimir: false, urlFrame: response.data.archivo, peticiones: peticiones.data.data })
            } else {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openImprimir: false })
                showNotification('error', 'Ups...Algo Sucedió', 'No se actualizo la tabla ', null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openImprimir: false })
            showNotification('error', 'Ups...Algo Sucedió', response.data.message, null, 4);
        }
    }

    const editarPeticion = async () => {
        setLoader({ ...loader, loading: true })
        if (state.peticionSeleccionada.respuesta === "") {
            let newErrors = {};
            newErrors.respuesta = "Este Campo es requerido"
            setErrors(newErrors);
            showNotification('error', 'Ups...', 'Favor de rellenar los campos marcados', null, 4);
            setLoader({ ...loader, loading: false })
            return Object.keys(newErrors).length === 0;
        } else {
            let response = await services({
                method: 'POST', service: `peticiones/editarPeticion`, body: {
                    id: state.peticionSeleccionada.id,
                    peticion: state.peticionSeleccionada.peticion?.toUpperCase(),
                    tipo_peticion_id: state.peticionSeleccionada.tipo_peticion_id,
                    dependencia_id: state.peticionSeleccionada.dependencia_id,
                    estatus_peticion_id: state.peticionSeleccionada.estatus_peticion_id,
                    respuesta: state.peticionSeleccionada.respuesta?.toUpperCase(),
                    atencion_inmediata: state.peticionSeleccionada.atencion_inmediata,
                }
            });
            if (response.status === 200) {
                let peticiones = await services({ method: 'POST', service: `peticiones/peticionesCiudadano`, body: { id: id } });
                if (peticiones.status === 200) {
                    setLoader({ ...loader, loading: false })
                    setState({ ...state, peticiones: peticiones.data.data, openPeticion: false })
                    showNotification('success', 'Éxito', 'Su petición ha sido editada', null, 4);
                    setErrors({});
                } else {
                    setLoader({ ...loader, loading: false })
                    setState({ ...state, openPeticion: false })
                    showNotification('error', 'Ups...Algo Sucedió', peticiones.data.message, null, 4);
                    setErrors({});
                }
            } else {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openPeticion: false })
                showNotification('error', 'Ups...Algo Sucedió', response.data.message, null, 4);
                setErrors({});
            }
        }
    }

    const eliminarPeticion = async () => {
        setLoader({ ...loader, loading: true })
        let response = await services({
            method: 'POST', service: `peticiones/eliminarPeticion`, body: {
                id: state.peticionSeleccionada.id,
            }
        });
        if (response.status === 200) {
            let peticiones = await services({ method: 'POST', service: `peticiones/peticionesCiudadano`, body: { id: id } });
            if (peticiones.status === 200) {
                setLoader({ ...loader, loading: false })
                setState({ ...state, peticiones: peticiones.data.data, openEliminar: false })
                showNotification('success', 'Éxito', 'Su petición ha sido eliminada', null, 4);
            } else {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openEliminar: false })
                showNotification('error', 'Ups...Algo Sucedió', peticiones.data.message, null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openEliminar: false })
            showNotification('error', 'Ups...Algo Sucedió', response.data.message, null, 4);
        }
    }

    const enviarArchivos = async () => {
        setLoader({ ...loader, loading: true })
        if (selectedFile !== null) {
            const formData = new FormData();
            formData.append("id", selectedItemId.id);
            formData.append("archivo", selectedFile);
            let response = await services({ method: 'POST', service: `documentos/adjuntos`, body: formData });
            if (response.status === 200) {
                let listaAdjuntos = await services({ method: 'POST', service: `documentos/listarAdjuntos`, body: { id: selectedItemId.id } });
                if (listaAdjuntos.status === 200) {
                    setState({ ...state, adjuntos: listaAdjuntos.data.data })
                    setLoader({ ...loader, loading: false })
                    showNotification('success', 'Éxito', 'Se ha cargado su archivo', null, 4);
                    setSelectedFile(null)
                    fileInputRef.current.value = '';
                }
            } else {
                setLoader({ ...loader, loading: false })
                showNotification('error', 'Ups...', response.data.message.archivo[0], null, 4);
                setSelectedFile(null)
                fileInputRef.current.value = '';
                handleCloseAdjuntos()
            }
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Favor de seleccionar un archivo', null, 4);
            setSelectedFile(null)
        }
    }

    const eliminarAdjunto = async (id_documento) => {

        setLoader({ ...loader, loading: true })
        const formData = new FormData();
        formData.append("id", id_documento);
        let response = await services({ method: 'POST', service: `documentos/eliminarAdjunto`, body: formData });
        if (response.status === 200) {
            let listaAdjuntos = await services({ method: 'POST', service: `documentos/listarAdjuntos`, body: { id: selectedItemId.id } });
            if (listaAdjuntos.status === 200) {
                setState({ ...state, adjuntos: listaAdjuntos.data.data })
                setLoader({ ...loader, loading: false })
                showNotification('success', 'Éxito', 'Se ha eliminado su archivo', null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Ocurrió algo al eliminar su archivo', null, 4);
            handleCloseAdjuntos()
        }
    }

    const descargarAdjunto = async (id_documento, name, tipo) => {
        setLoader({ ...loader, loading: true })
        const formData = new FormData();
        formData.append("id", selectedItemId.id);
        formData.append("id_documento", id_documento);
        formData.append("tipo_documento_id", tipo);
        let response = await services({
            method: 'POST', service: `documentos/descargarDocumento`, body: formData
        });
        if (response.status === 200) {
            setLoader({ ...loader, loading: false })
            showNotification('success', 'Éxito', 'Se ha descargado su archivo', null, 4);
            const link = document.createElement('a');
            link.href = `data:application/octet-stream;base64,${response.data.archivo}`;
            link.download = name;
            link.click();
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Ocurrió algo al descargar su archivo', null, 4);
        }
    }


    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarPeticiones()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>
                {loader.loading &&
                    <LoaderComponent />
                }
                <div className='container'>
                    <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center" >

                        <Grid item  >
                            <ColorButton startIcon={<IoMdArrowRoundBack />} onClick={() => { goTo(-1); setPeticion(''); }}>
                                ATRÁS
                            </ColorButton>
                        </Grid>
                    </Grid>
                </div>

                <DataTable
                    data={state.peticiones}
                    title={`TABLA DE PETICIONES CIUDADANO`}
                    columns={columns}
                />
                {/* Modal para editar peticion */}
                <Modal
                    open={state.openPeticion}
                    onClose={() => handleClosePeticion()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style2}>
                        <h3 className='TitulosProgramas mb-4'>
                            EDITAR PETICIÓN
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <Autocomplete
                                    options={state.tiposPeticion || []}
                                    getOptionLabel={(option) => option.tipo_peticion}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={state.tiposPeticion.find(option => option.id === state.peticionSeleccionada.tipo_peticion_id) || null}
                                    onChange={(event, newValue) => handleTipoPeticiones(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="TIPO DE PETICIÓN"
                                            error={!!errors.tipo}
                                            helperText={errors.tipo || ''}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    size='small'
                                    label="PETICIÓN"
                                    error={!!errors.peticion}
                                    rows={4}
                                    helperText={errors.peticion || ''}
                                    value={state.peticionSeleccionada.peticion}
                                    onChange={({ target }) => setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, peticion: target.value } })}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    options={state.dependencias || []}
                                    getOptionLabel={(option) => option.dependencia}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={state.dependencias.find(option => option.id === state.peticionSeleccionada.dependencia_id) || null}
                                    onChange={(event, newValue) => handleDependencias(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="DEPENDENCIAS"
                                            error={!!errors.dependencia_id}
                                            helperText={errors.dependencia_id || ''}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="PRIORIDAD"
                                    variant="outlined"
                                    select
                                    error={!!errors.atencion}
                                    helperText={errors.atencion || ''}
                                    onChange={handlePrioridad}
                                    value={state.peticionSeleccionada.atencion_inmediata}
                                >
                                    <MenuItem value={1}>
                                        INMEDIATA
                                    </MenuItem>
                                    <MenuItem value={0}>
                                        ALTA
                                    </MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="ESTADO"
                                    variant="outlined"
                                    select
                                    error={!!errors.atencion}
                                    helperText={errors.atencion || ''}
                                    onChange={handleStatusPeticion}
                                    value={state.peticionSeleccionada.estatus_peticion_id}
                                >
                                    <MenuItem value={1}>
                                        TURNADA
                                    </MenuItem>
                                    <MenuItem value={4}>
                                        ATENDIDA POSITIVA
                                    </MenuItem>
                                    <MenuItem value={5}>
                                        NO CORRESPONDE
                                    </MenuItem>
                                    <MenuItem value={6}>
                                        NO PROCEDENTE
                                    </MenuItem>
                                    <MenuItem value={7}>
                                        ARCHIVO
                                    </MenuItem>
                                    <MenuItem value={8}>
                                        EN TRAMITE
                                    </MenuItem>
                                    <MenuItem value={9}>
                                        CANCELADO
                                    </MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    size='small'
                                    label="RESPUESTA"
                                    rows={4}
                                    error={!!errors.respuesta}
                                    helperText={errors.respuesta || ''}
                                    value={state.peticionSeleccionada.respuesta}
                                    onChange={({ target }) => setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, respuesta: target.value } })}
                                />
                            </Grid>


                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarPeticion();
                                }}
                                >
                                    EDITAR
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para ver detalles */}
                <Modal
                    open={state.openDetalles}
                    onClose={() => handleCloseDetalles()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas mb-4'>
                            DETALLES
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <h5 className='mt-3 border-bottom' >DATOS SOLICITUD</h5>
                            </Grid>

                            <Grid item xs={12}>
                                <p>FOLIO: {state.detalles.folio_solicitud}</p>
                                <p>FECHA CREACIÓN: {state.detalles.fecha_creacion_solicitud}</p>
                                <p>FUENTE DE SOLICITUD: {state.detalles.fuente_solicitud}</p>
                                <p>OBSERVACIONES: {state.detalles.observaciones}</p>
                                <p>NÚMERO DE GIRA: {state.detalles.numero_gira}</p>
                                <p>LUGAR DE GIRA: {state.detalles.lugar_gira}</p>
                            </Grid>


                            <Grid item xs={12}>
                                <h5 className='mt-2 border-bottom' >DATOS PETICIÓN</h5>
                            </Grid>

                            <Grid item xs={12}>
                                <p>FOLIO: {state.detalles.folio}</p>
                                <p>ALTA: {state.detalles.fecha_creacion}</p>
                                <p>USUARIO DE REGISTRO: {state.detalles.usuario_registro}</p>
                                <p>CIUDADANO: {state.detalles.ciudadano}</p>
                                <p>CARGO: {state.detalles.cargo_ciudadano}</p>
                                <p>DIRECCIÓN: {state.detalles.Direccion}</p>
                                <p>TELÉFONO: {state.detalles.telefono}</p>
                                <p>DEPENDENCIA: {state.detalles.dependencia}</p>
                                <p>DESCRIPCIÓN: {state.detalles.tipo_peticion? state.detalles.tipo_peticion +" "+ state.detalles.peticion : state.detalles.peticion}</p>
                                <p>ESTADO: {state.detalles.estatus_peticion}</p>
                                <p>RESPUESTA: {state.detalles.respuesta}</p>
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    handleCloseDetalles();
                                }}
                                >
                                    CERRAR
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para imprimir oficios */}
                <Modal
                    open={state.openImprimir}
                    onClose={() => handleCloseImprimir()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style2}>
                        <h3 className='TitulosProgramas mb-4'>
                            OFICIOS
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="TIPO DE OFICIO"
                                    variant="outlined"
                                    select
                                    onChange={handleDocumento}
                                    value={state.documento}
                                >
                                    <MenuItem value={1}>
                                        OFICIO PARA AYUNTAMIENTOS
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        OFICIO PARA DEPENDENCIA ESTATAL
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        OFICIO PARA DEPENDENCIA FEDERAL
                                    </MenuItem>
                                    <MenuItem value={4}>
                                        OFICIO PARA ORGANISMO AUTÓNOMO
                                    </MenuItem>
                                    <MenuItem value={6}>
                                        Oficio Respuesta Petición
                                    </MenuItem>
                                </TextField>
                            </Grid>


                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    descargarDocumentos(state.documento);
                                }}
                                >
                                    GENERAR
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para ver documento */}
                <Modal
                    open={state.openDocumento}
                    onClose={() => handleCloseDocumento()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style3}>
                        <p className="text-center">DOCUMENTO</p>
                        <iframe style={{ width: "100%", height: "100%" }} title='DOCUMENTO' id="frameAceptacion" src={`data:application/pdf;base64,${state.urlFrame}`} className="preview-iframe" ></iframe>
                        <Button sx={{ mt: 2 }} onClick={() => handleCloseDocumento()} >CERRAR</Button>
                    </Box>
                </Modal>
                {/* Modal para ver Adjuntos */}
                <Modal
                    open={state.openAdjuntos}
                    onClose={() => handleCloseAdjuntos()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style2}>
                        <h3 className='TitulosProgramas mb-4'>
                            ARCHIVOS ADJUNTOS
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={2}
                        >
                            {state.adjuntos ?
                                state.adjuntos.map((item) => {
                                    return (
                                        <Grid item xs={12}>
                                            <Grid container columns={2} spacing={2} >
                                                <Grid item xs={1}>
                                                    <Link
                                                        component="button"
                                                        variant="body2"
                                                        onClick={() => {
                                                            descargarAdjunto(item.id, item.ruta, item.tipo_documento_id)
                                                        }}
                                                    >
                                                        {item.ruta}
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={1} align="end" >

                                                    <Button onClick={() => { eliminarAdjunto(item.id) }}>
                                                        <IconUI size={'20px'} color={'red'} ><AiOutlineDelete /></IconUI>
                                                    </Button>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                                :
                                <></>
                            }


                            <Grid item xs={12}  >
                                <input
                                    accept="*/*"
                                    style={{ display: 'none' }}
                                    id="file-upload-input"
                                    type="file"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                />
                                <label htmlFor="file-upload-input">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        SUBIR ARCHIVO
                                    </Button>
                                </label>
                                {selectedFile && (
                                    <div>
                                        <p>Nombre del archivo: {selectedFile.name}</p>
                                    </div>
                                )}
                            </Grid>






                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    enviarArchivos();
                                }}
                                >
                                    ENVIAR
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para ver descripción/respuesta */}
                <Modal
                    open={state.openDescripcion}
                    onClose={() => handleCloseDescripcion()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box className='contenedorModalUrs' sx={style2} >
                        <Grid
                            container
                            columns={12}
                            spacing={2}
                        >
                            <Grid item xs={12} >
                                <p>{state.descripcion}</p>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para eliminar peticion */}
                <Modal
                    open={state.openEliminar}
                    onClose={() => handleCloseEliminar()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style2}>
                        <h3 className='TitulosProgramas mb-3'>
                            ¿ESTÁ SEGURO DE ELIMINAR LA PETICIÓN?
                        </h3>

                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <h4>FOLIO: {state.peticionSeleccionada.folio}</h4>
                                <h6>DESCRIPCIÓN:  {state.peticionSeleccionada.peticion} </h6>
                            </Grid>
                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    eliminarPeticion();
                                }}

                                >
                                    Eliminar
                                </ColorButton>
                            </Grid>
                        </Grid>

                    </Box>
                </Modal>
            </div>
        </>
    )
}





export default PeticionesCiudadano;
import { useNavigate } from "react-router-dom"

const InformacionPublicaView = () => {
    const navigate = useNavigate()
    const handleGotoConsultaPeticion  = () =>{
        navigate("/consulta-peticion")
    }
    const handleGotoCrearPeticion = () =>{
        navigate("/crear-peticion")
    }
    return (
        <div className="container py-3">
            <div className="text-big pt-3">TRÁMITES Y SERVICIOS EN LÍNEA</div>
            <div className="card card--rounded my-3 p-3">
                <div>¿Cómo Realizar una Petición Ciudadana?</div>
                <ol className="mt-3">
                    <li>Acudir directamente a las oficinas de la Coordinación de Atención Ciudadana, en Palacio de Gobierno Tercer Patio, Planta Baja, Morelia, Mich.</li>
                    <li>Enviar una petición escrita, vía correspondencia a las oficinas de la oficialía de partes en Palacio de Gobierno, ubicada en Av. Madero Pte. No. 63, Col. Centro, C.P. 58000.</li>
                    <li>Durante las giras de trabajo del C. Gobernador del Estado, entregar su petición al personal de la Coordinación de Atención Ciudadana.</li>
                    <li>*NUEVO-----. Ahora también puedes realizar Tú Petición Ciudadana EN LINEA y darle Seguimiento conociendo los procesos por los que pasa en la dirección electrónica: <span><a href="https://www.michoacan.gob.mx/atencion-ciudadana/">https://www.michoacan.gob.mx/atencion-ciudadana/.</a></span> </li>
                </ol>

                <div className="mt-3">Toda Petición Deberá Contener los Siguientes Datos:
                </div>
                <ol className="mt-3">
                    <li>Documento Dirigido al Lic Alfredo Ramírez Bedolla, Gobernador del Estado de Michoacán.</li>
                    <li>Nombre Completo del peticionario.</li>
                    <li> Domicilio: Calle, Número, Colonia, C.P. Localidad y Municipio.</li>
                    <li>Detallar con claridad la petición.</li>
                    <li>Firma ó nombre del peticionario.</li>
                </ol>
                <div className="d-flex justify-conten-center align-items-center mt-3">
                    <div
                        className="cta cta--guinda me-3 text-center"
                        style={{ width: '150px', height: '90px' }}
                        onClick={handleGotoConsultaPeticion}
                    >Conocer la respuesta de mi petición</div>
                    <div
                        className="cta cta--guinda d-flex justify-conten-center align-items-center"
                        style={{ width: '150px', height: '90px' }}
                        onClick={handleGotoCrearPeticion}
                    >
                        <div className="text-center">Crear nueva Petición</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InformacionPublicaView
import React from 'react'

export default function OverLayIU(props) {
    
    return (
        <div className={`overlay overlay--${props.type} overlay--${props.overlay}`} onClick={()=>{
            props.onClick()
        }}>
        </div>

    )
}

import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../components/DataTableComponents';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField, MenuItem } from '@mui/material';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';
import { maxLengthLimitInput, onlyNumber } from '../utils/inputsRules';
import Tooltip from '@mui/material/Tooltip';
import IconUI from '../components/iu/icon_ui';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';


const Dependencias = () => {


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        //border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 600,
        borderRadius: "40px",
        scrollbarWidth: "none",
    };

    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        //border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 'auto',
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));


    const [state, setState] = useState({
        dependencias: [],
        dependenciaSeleccionada: {},
        open: false,
        openModalEliminar: false

    });

    // const [tituloState, setTitulo] = useState({ data: [] })
    const [cargoState, setCargo] = useState({ data: [] })

    const { showNotification } = useContext(NotificacionContext)

    const [formularioState, setFormularioState] = useState(
        {
            clave: '',
            siglas: '',
            nombre: '',
            telefono: '',
            correo: '',
            calle: '',
            numero: '',
            cp: '',
            colonia: '',
            municipio: '',
            localidad: '',
            estado: '',
            tipo_gobierno: '',
            titular: '',
            titulo_id: '',
            cargo: '',
        }
    );

    const [errors, setErrors] = useState({});


    const openmodal = (data) => {
        const newData = data;
        setEditar({ ...editarState, open: true, data: newData });
    }

    const handleClose = () => {
        setEditar({
            ...editarState, open: false
        });
    }

    const openModalEliminar = (data) => {
        setState({ ...state, openModalEliminar: true, dependenciaSeleccionada: data });
    }
    const handleCloseEliminar = () => {
        setState({
            ...state, openModalEliminar: false
        });
    }

    // manejo de nueva dependencia

    const handleClave = (e) => {
        setFormularioState({ ...formularioState, clave: e.target.value });
    }
    const handleSiglas = (e) => {
        setFormularioState({ ...formularioState, siglas: e.target.value });
    }
    const handleNombre = (e) => {
        setFormularioState({ ...formularioState, nombre: e.target.value })
    }
    const handleTipoGobierno = (e) => {
        setFormularioState({ ...formularioState, tipo_gobierno: e.target.value })
    }
    const handleTelefono = (e) => {
        setFormularioState({ ...formularioState, telefono: e.target.value });
    }
    const handleCorreo = (e) => {
        setFormularioState({ ...formularioState, correo: e.target.value });
    }

    const handleCalle = (e) => {
        setFormularioState({ ...formularioState, calle: e.target.value });
    }
    const handleNumero = (e) => {
        setFormularioState({ ...formularioState, numero: e.target.value });
    }
    const handleCP = (e) => {
        setFormularioState({ ...formularioState, cp: e.target.value });
    }
    const handleColonia = (e) => {
        setFormularioState({ ...formularioState, colonia: e.target.value });
    }
    const handleMunicipio = (e) => {
        setFormularioState({ ...formularioState, municipio: e.target.value });
    }
    const handleLocalidad = (e) => {
        setFormularioState({ ...formularioState, localidad: e.target.value });
    }
    const handleEstado = (e) => {
        setFormularioState({ ...formularioState, estado: e.target.value });
    }

    // const handleTitulo = (e) => {
    //     setFormularioState({ ...formularioState, titulo_id: e.target.value })
    // }
    const handleTitular = (e) => {
        setFormularioState({ ...formularioState, titular: e.target.value })
    }
    const handleCargo = (e) => {
        setFormularioState({ ...formularioState, cargo: e.target.value })

    }

    const [editarState, setEditar] = useState({
        open: false,
        data: {}
    })


    // Editar dependencia
    const handleSeleccionadaClave = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, clave: e.target.value } })
    }
    const handleSeleccionadaSiglas = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, siglas: e.target.value } })
    }
    const handleSeleccionadaNombre = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, dependencia: e.target.value } })
    }
    const handleSeleccionadaTipoGobierno = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, tipo_gobierno: e.target.value } })
    }
    const handleSeleccionadaTelefono = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, telefono: e.target.value } })
    }
    const handleSeleccionadaCorreo = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, email: e.target.value } })
    }

    const handleSeleccionadaCalle = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, calle: e.target.value } })
    }
    const handleSeleccionadaNumero = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, numero_ext: e.target.value } })
    }
    const handleSeleccionadaCP = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, cp: e.target.value } })

    }
    const handleSeleccionadaColonia = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, colonia: e.target.value } })
    }
    const handleSeleccionadaMunicipio = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, municipio: e.target.value } })
    }
    const handleSeleccionadaLocalidad = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, localidad: e.target.value } })
    }
    const handleSeleccionadaEstado = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, estado: e.target.value } })
    }
    // const handleSeleccionadaTitulo = (e) => {
    //     setEditar({ ...editarState, data: { ...editarState.data, cat_tipo_titulo_id: e.target.value } })
    // }
    const handleSeleccionadaTitular = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, funcionario: e.target.value } })
    }
    const handleSeleccionadaCargo = (e) => {
        setEditar({ ...editarState, data: { ...editarState.data, cargo: e.target.value } })
    }


    // Validaciones y peticiones

    const validarFormulario = () => {
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let newErrors = {};
        if (!formularioState.nombre) { newErrors.nombre = 'Este Campo es Requerido' }
        if (!formularioState.tipo_gobierno) { newErrors.tipo_gobierno = 'Este Campo es Requerido' }
        // if (formularioState.telefono !== "" && formularioState.telefono.length < 9) { newErrors.telefono = 'Favor poner un número de 10 dígitos' }
        if (formularioState.cp !== "" && formularioState.cp.length < 5) { newErrors.cp = 'Favor poner un número de 5 dígitos' }
        if (formularioState.correo !== "") {

            let valido = formularioState.correo.match(regexEmail)
            if (!valido) {
                newErrors.email = 'Ingrese un email válido'
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;

    }

    const validarEditar = () => {
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let newErrors = {};
        if (!editarState.data.dependencia) { newErrors.dependencia = 'Este Campo es Requerido' }
        if (!editarState.data.tipo_gobierno) { newErrors.tipo_gobierno = 'Este Campo es Requerido' }
        // if (editarState.data.telefono !== null && editarState.data.telefono.length < 10) { newErrors.telefono = 'Favor poner un número de 10 dígitos' }
        if (editarState.data.cp !== null && editarState.data.cp !== "") {

            if (editarState.data.cp.length < 5) {
                newErrors.cp = 'Favor poner un número de 5 dígitos'
            }
        }

        if (editarState.data.email !== null && editarState.data.email !== "") {
            let valido = editarState.data.email.match(regexEmail)
            if (!valido) {
                newErrors.email = 'Ingrese un email válido'
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const limpiarForm = () => {
        setFormularioState({
            ...formularioState,
            clave: '',
            siglas: '',
            nombre: '',
            telefono: '',
            correo: '',
            calle: '',
            numero: '',
            cp: '',
            colonia: '',
            municipio: '',
            localidad: '',
            estado: '',
            tipo_gobierno: '',
            titular: '',
            titulo_id: '',
            cargo: ''
        })
    }

    const crearDependencia = async () => {


        if (validarFormulario()) {
            setLoader(true)
            const dependencia = await services({
                method: 'POST', service: 'dependencias/crear', body: {
                    clave: formularioState.clave,
                    dependencia: formularioState.nombre,
                    siglas: formularioState.siglas,
                    telefono: formularioState.telefono,
                    email: formularioState.correo,
                    numero_ext: formularioState.numero,
                    calle: formularioState.calle,
                    colonia: formularioState.colonia,
                    municipio: formularioState.municipio,
                    localidad: formularioState.localidad,
                    estado: formularioState.estado,
                    cp: formularioState.cp,
                    tipo_gobierno: formularioState.tipo_gobierno,
                    funcionario: formularioState.titular,
                    cargo: formularioState.cargo,
                    // cat_tipo_titulo_id: formularioState.titulo_id
                }
            })
            if (dependencia.status === 200) {
                let dependencias = await services({ method: 'GET', service: `dependencias/listar`, body: null });
                if (dependencias.status === 200) {
                    setState({ ...state, dependencias: dependencias.data.data })
                    showNotification('success', 'Dependencias', 'Se creó dependencia correctamente', null, 4);
                    setopenModal(false);
                    limpiarForm();
                } else {
                    showNotification('error', 'ups... Algo sucedió', 'Error al agregar dependencia', null, 4);
                    setopenModal(false);
                    limpiarForm();
                }
            } else {
                showNotification('error', 'Ups... Algo sucedió', dependencia.data.message, null, 4);
                setopenModal(false);
                limpiarForm();
            }

        } else {
            showNotification('error', 'ups...Error ', 'Por favor llena todos los campos', null, 4);
        }
    }

    const editarDependencia = async () => {
        setLoader(true)
        const body = {
            id: editarState.data.id,
            clave: editarState.data.clave?.toUpperCase(),
            dependencia: editarState.data.dependencia?.toUpperCase(),
            siglas: editarState.data.siglas?.toUpperCase(),
            telefono: editarState.data.telefono,
            email: editarState.data.email?.toUpperCase(),
            numero_ext: editarState.data.numero_ext?.toUpperCase(),
            calle: editarState.data.calle?.toUpperCase(),
            colonia: editarState.data.colonia?.toUpperCase(),
            municipio: editarState.data.municipio?.toUpperCase(),
            localidad: editarState.data.localidad?.toUpperCase(),
            estado: editarState.data.estado?.toUpperCase(),
            cp: editarState.data.cp,
            tipo_gobierno: editarState.data.tipo_gobierno,
            funcionario: editarState.data.funcionario?.toUpperCase(),
            cargo: editarState.data.cargo,
            // cat_tipo_titulo_id: editarState.data.cat_tipo_titulo_id,
        }
        if (validarEditar()) {
            setLoader(true)
            const EditDependencia = await services({ method: 'POST', service: `dependencias/editar`, body })
            if (EditDependencia.status === 200) {
                let dependencias = await services({ method: 'GET', service: `dependencias/listar`, body: null })
                if (dependencias.status === 200) {
                    setState({ ...state, dependencias: dependencias.data.data })
                    showNotification('success', 'Dependencias', 'Se editó correctamente la dependencia', null, 4);
                    setLoader(false)
                    setEditar({ ...editarState, open: false })
                } else {
                    showNotification('error', 'Ups...Algo Sucedió', 'Error al editar', null, 4)
                    setLoader(false)
                    setEditar({ ...editarState, open: false })
                }
            } else {
                showNotification('error', 'Ups... Sucedió', 'Error al editar', null, 4)
                setLoader(false)
                setEditar({ ...editarState, open: false })
            }
        } else {
            showNotification('error', 'Por favor', 'Verifica los campos marcados', null, 4)
        }
    }

    const eliminarDependencia = async () => {
        setLoader({ loading: true })
        const eliminarDependencia = await services({
            method: 'POST', service: `dependencias/eliminar`, body: {
                id: state.dependenciaSeleccionada.id,
            }
        })
        if (eliminarDependencia) {
            setLoader({ loading: true })
            let dependencias = await services({ method: 'GET', service: `dependencias/listar`, body: null })
            if (dependencias) {
                setState({ ...state, dependencias: dependencias.data.data, openModalEliminar: false })
                showNotification('success', 'Dependencia Eliminada con Éxito', '', null, 4);
                setLoader({ loading: false })
            } else {
                setLoader({ loading: false })
                showNotification('error', 'Ups...', 'Error al eliminar la dependencia', null, 4);
                setState({ ...state, openModalEliminar: false })
            }
        } else {
            setLoader({ loading: false })
            showNotification('error', 'Ups...', 'Error al eliminar la dependencia', null, 4);
            setState({ ...state, openModalEliminar: false })

        }

    }

    const [loader, setLoader] = useState({
        loading: true
    });

    const [openModal, setopenModal] = useState(false)


    const mostrarDependencias = async () => {

        let dependencias = await services({ method: 'GET', service: `dependencias/listar`, body: null });

        if (dependencias) {
            setState({ ...state, dependencias: dependencias.data.data })
        }
        setLoader(false)
    }

    // const mostrarTitulos = async () => {

    //     let titulos = await services({ method: 'GET', service: `catTiposTitulos/listar`, body: null });

    //     if (titulos) {
    //         setTitulo({ ...tituloState, data: titulos.data.data })
    //     }
    //     setLoader(false)
    // }

    const mostrarCargos = async () => {

        let cargos = await services({ method: 'GET', service: `catTipoCargos/listarCargos`, body: null });

        if (cargos) {
            setCargo({ ...cargoState, data: cargos.data.data })
        }
        setLoader(false)

    }

    const columns = [
        {
            name: "clave",
            label: "CLAVE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let clave = state.dependencias[dataIndex].clave;
                    if (clave !== null) {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center">{clave}</div>)
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex  justify-content-center align-items-center">-</div>)

                    }
                }
            }
        },
        {
            name: "dependencia",
            label: "DEPENDENCIA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let dependencia = state.dependencias[dataIndex].dependencia;
                    return (<div className=' d-flex align-items-center' style={{ height: '95px' }}>{dependencia}</div>)
                }
            }
        },
        {
            name: "siglas",
            label: "SIGLAS",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let siglas = state.dependencias[dataIndex].siglas;
                    if (siglas !== null && siglas !== "") {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center">{siglas}</div>)
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex  justify-content-center align-items-center">-</div>)

                    }
                }
            }
        },
        {
            name: "calle",
            label: "UBICACIÓN",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let ubicacion = state.dependencias[dataIndex].calle;
                    if (ubicacion !== null) {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center">{ubicacion}</div>)
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex  justify-content-center align-items-center">-</div>)

                    }
                }
            }
        },
        {
            name: "telefono",
            label: "TELÉFONO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let telefono = state.dependencias[dataIndex].telefono;
                    if (telefono !== null) {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center">{telefono}</div>)
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex  justify-content-center align-items-center">-</div>)

                    }
                }
            }
        },
        {
            name: "funcionario",
            label: "TITULAR",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let titular = state.dependencias[dataIndex].funcionario;
                    if (titular !== null) {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center">{titular}</div>)
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex  justify-content-center align-items-center">-</div>)

                    }
                }
            }
        },

        {
            name: "cargo",
            label: "CARGO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let cargo = state.dependencias[dataIndex].cargo;
                    if (cargo !== null) {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center">{cargo}</div>)
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex  justify-content-center align-items-center">-</div>)

                    }
                }
            }
        },

        {
            name: "acciones",
            label: "ACCIONES",

            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px' }} className={i % 2 === 0 ? "w-100 d-flex align-items-center justify-content-center " : "w-100 d-flex justify-content-center align-items-center "}>
                            <Tooltip title="EDITAR DEPENDENCIA">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openmodal(state.dependencias[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                </div>
                            </Tooltip>
                            <Tooltip title="ELIMINAR DEPENDENCIA">
                                <div style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openModalEliminar(state.dependencias[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineDelete /></IconUI>
                                </div>
                            </Tooltip>


                        </div>
                    </>
                )
            }
        },


    ];


    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarDependencias()
            // mostrarTitulos()
            mostrarCargos()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>
                {loader.loading &&
                    <LoaderComponent />
                }
                <div className='container'>
                    <Grid sx={{ marginTop: 3 }} container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                        <Grid item >
                            <ColorButton startIcon={<AddCircleOutlineIcon />} onClick={() => { setopenModal(true) }}>
                                Añadir Nueva Dep
                            </ColorButton>
                        </Grid>
                    </Grid>
                </div>
                <DataTable
                    data={state.dependencias}
                    title={`TABLA DE DEPENDENCIAS`}
                    columns={columns}
                />

                {/*Modal para crear */}

                <Modal
                    open={openModal}
                    onClose={() => setopenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            NUEVA DEPENDENCIA
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    // error={formularioState.claveError}
                                    id="textFile-nombre"
                                    label="CLAVE DEPENDENCIA"
                                    variant="outlined"
                                    value={formularioState.clave}
                                    onChange={handleClave}

                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    // error={formularioState.siglasError}
                                    id="textFile-nombre"
                                    label="SIGLAS"
                                    variant="outlined"
                                    value={formularioState.siglas}
                                    onChange={handleSiglas}

                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    error={!!errors.nombre}
                                    helperText={errors.nombre || ''}
                                    id="textFile-nombre"
                                    label="NOMBRE DEPENDENCIA"
                                    variant="outlined"
                                    value={formularioState.nombre}
                                    onChange={handleNombre}

                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="TIPO DE GOBIERNO"
                                    variant="outlined"
                                    select
                                    error={!!errors.tipo_gobierno}
                                    helperText={errors.tipo_gobierno || ''}
                                    value={formularioState.tipo_gobierno}
                                    onChange={handleTipoGobierno}

                                >
                                    <MenuItem value="FEDERAL">FEDERAL
                                    </MenuItem>
                                    <MenuItem value="ESTATAL">ESTATAL
                                    </MenuItem>
                                    <MenuItem value="MUNICIPAL">MUNICIPAL
                                    </MenuItem>
                                    <MenuItem value="AUTONOMO">AUTÓNOMO
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    error={!!errors.telefono}
                                    helperText={errors.telefono || ''}
                                    id="textFile-nombre"
                                    label="TELÉFONO PRINCIPAL"
                                    variant="outlined"
                                    value={formularioState.telefono}
                                    onChange={handleTelefono}
                                    inputProps={{ maxLength: 150 }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    error={!!errors.email}
                                    helperText={errors.email || ''}
                                    id="textFile-nombre"
                                    label="CORREO ELECTRÓNICO"
                                    variant="outlined"
                                    value={formularioState.correo}
                                    onChange={handleCorreo}
                                    onKeyPress={(event) => {
                                        maxLengthLimitInput(event, 55, 'email');
                                    }}
                                />
                            </Grid>
                            <hr />
                            <hr />
                            <Grid item xs={12}>
                                <h5 className='mt-3'>DIRECCIÓN</h5>
                            </Grid>
                            <hr />
                            <hr />
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="CALLE"
                                    variant="outlined"
                                    value={formularioState.calle}
                                    onChange={handleCalle}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="NÚMERO"
                                    variant="outlined"
                                    value={formularioState.numero}
                                    onChange={handleNumero}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="C.P."
                                    variant="outlined"
                                    error={!!errors.cp}
                                    helperText={errors.cp || ''}
                                    value={formularioState.cp}
                                    onChange={handleCP}
                                    inputProps={{ maxLength: 5 }}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="COLONIA"
                                    variant="outlined"
                                    value={formularioState.colonia}
                                    onChange={handleColonia}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="MUNICIPIO"
                                    variant="outlined"
                                    value={formularioState.municipio}
                                    onChange={handleMunicipio}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="LOCALIDAD"
                                    variant="outlined"
                                    value={formularioState.localidad}
                                    onChange={handleLocalidad}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="ESTADO"
                                    variant="outlined"
                                    value={formularioState.estado}
                                    onChange={handleEstado}
                                />
                            </Grid>
                            <hr />
                            <hr />
                            <Grid item xs={12}>
                                <h5 className='mt-3'>DATOS</h5>
                            </Grid>
                            <hr />
                            <hr />


                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    // error={formularioState.titularError}
                                    id="textFile-nombre"
                                    label="TITULAR"
                                    variant="outlined"
                                    value={formularioState.titular}
                                    onChange={handleTitular}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    error={formularioState.tituloError}
                                    id="textFile-nombre"
                                    label="TÍTULO"
                                    variant="outlined"
                                    select
                                    value={formularioState.titulo_id}
                                    onChange={handleTitulo}
                                >
                                    {tituloState.data && tituloState.data.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.tipo_titulo}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid> */}


                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    // error={formularioState.titularError}
                                    id="textFile-nombre"
                                    label="CARGO"
                                    variant="outlined"
                                    value={formularioState.cargo}
                                    onChange={handleCargo}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    crearDependencia();
                                }}
                                >
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Editar */}
                <Modal
                    open={editarState.open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            EDITAR DEPENDENCIA
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    // error={formularioState.claveError}
                                    id="textFile-nombre"
                                    label="CLAVE DEPENDENCIA"
                                    variant="outlined"
                                    value={editarState.data.clave}
                                    onChange={handleSeleccionadaClave}

                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    // error={formularioState.siglasError}
                                    id="textFile-nombre"
                                    label="SIGLAS"
                                    variant="outlined"
                                    value={editarState.data.siglas}
                                    onChange={handleSeleccionadaSiglas}

                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    error={!!errors.dependencia}
                                    helperText={errors.dependencia || ''}
                                    id="textFile-nombre"
                                    label="NOMBRE DEPENDENCIA"
                                    variant="outlined"
                                    value={editarState.data.dependencia}
                                    onChange={handleSeleccionadaNombre}

                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="TIPO DE GOBIERNO"
                                    variant="outlined"
                                    select
                                    error={!!errors.tipo_gobierno}
                                    helperText={errors.tipo_gobierno || ''}
                                    value={editarState.data.tipo_gobierno}
                                    onChange={handleSeleccionadaTipoGobierno}

                                >
                                    <MenuItem value="FEDERAL">FEDERAL
                                    </MenuItem>
                                    <MenuItem value="ESTATAL">ESTATAL
                                    </MenuItem>
                                    <MenuItem value="MUNICIPAL">MUNICIPAL
                                    </MenuItem>
                                    <MenuItem value="AUTONOMO">AUTÓNOMO
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    error={!!errors.telefono}
                                    helperText={errors.telefono || ''}
                                    id="textFile-nombre"
                                    label="TELÉFONO PRINCIPAL"
                                    variant="outlined"
                                    value={editarState.data.telefono}
                                    onChange={handleSeleccionadaTelefono}
                                    inputProps={{ maxLength: 150 }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    error={!!errors.email}
                                    helperText={errors.email || ''}
                                    id="textFile-nombre"
                                    label="CORREO ELECTRÓNICO"
                                    variant="outlined"
                                    value={editarState.data.email}
                                    onChange={handleSeleccionadaCorreo}
                                    onKeyPress={(event) => {
                                        maxLengthLimitInput(event, 55, 'email');
                                    }}
                                />
                            </Grid>
                            <hr />
                            <hr />
                            <Grid item xs={12}>
                                <h5 className='mt-3'>DIRECCIÓN</h5>
                            </Grid>
                            <hr />
                            <hr />
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="CALLE"
                                    variant="outlined"
                                    value={editarState.data.calle}
                                    onChange={handleSeleccionadaCalle}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="NÚMERO"
                                    variant="outlined"
                                    value={editarState.data.numero_ext}
                                    onChange={handleSeleccionadaNumero}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    error={!!errors.cp}
                                    helperText={errors.cp || ''}
                                    size='small'
                                    id="textFile-nombre"
                                    label="C.P"
                                    variant="outlined"
                                    value={editarState.data.cp}
                                    onChange={handleSeleccionadaCP}
                                    inputProps={{ maxLength: 5 }}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="COLONIA"
                                    variant="outlined"
                                    value={editarState.data.colonia}
                                    onChange={handleSeleccionadaColonia}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="MUNICIPIO"
                                    variant="outlined"
                                    value={editarState.data.municipio}
                                    onChange={handleSeleccionadaMunicipio}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="LOCALIDAD"
                                    variant="outlined"
                                    value={editarState.data.localidad}
                                    onChange={handleSeleccionadaLocalidad}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    // error={formularioState.estadoError}
                                    size='small'
                                    id="textFile-nombre"
                                    label="ESTADO"
                                    variant="outlined"
                                    value={editarState.data.estado}
                                    onChange={handleSeleccionadaEstado}
                                />
                            </Grid>
                            <hr />
                            <hr />
                            <Grid item xs={12}>
                                <h5 className='mt-3'>DATOS</h5>
                            </Grid>
                            <hr />
                            <hr />


                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    // error={formularioState.titularError}
                                    id="textFile-nombre"
                                    label="TITULAR"
                                    variant="outlined"
                                    value={editarState.data.funcionario}
                                    onChange={handleSeleccionadaTitular}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    // error={formularioState.tituloError}
                                    id="textFile-nombre"
                                    label="TÍTULO"
                                    variant="outlined"
                                    select
                                    value={editarState.data.cat_tipo_titulo_id}
                                    onChange={handleSeleccionadaTitulo}
                                >
                                    {tituloState.data && tituloState.data.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.tipo_titulo}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid> */}

                            <Grid item xs={12}>
                            <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    // error={formularioState.titularError}
                                    id="textFile-nombre"
                                    label="CARGO"
                                    variant="outlined"
                                    value={editarState.data.cargo}
                                    onChange={handleSeleccionadaCargo}
                                />
                            </Grid>


                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarDependencia();

                                }}

                                >
                                    Editar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Eliminar */}
                <Modal
                    open={state.openModalEliminar}
                    onClose={handleCloseEliminar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style2}>
                        <h3 className='TitulosProgramas  mb-5'>
                            ¿ESTÁ SEGURO DE ELIMINAR LA DEPENDENCIA?
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={4}
                        >

                            <Grid item xs={12}>
                                <h4>
                                    {state.dependenciaSeleccionada.dependencia}
                                </h4>
                            </Grid>

                            <Grid margin="auto" item >
                                <ColorButton onClick={() => {
                                    eliminarDependencia();

                                }}>
                                    Eliminar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </div>
        </>
    )
}





export default Dependencias;

import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../components/DataTableComponents';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Tooltip } from '@mui/material';
import IconUI from '../components/iu/icon_ui';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField } from '@mui/material';
import { onlyLetter } from '../utils/inputsRules';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';

const FuentesDeSolicitud = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        //border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "auto",
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const [state, setState] = useState({
        fuentes: [],
        fuenteSeleccionado: {},
        openEditar: false,
        openEliminar: false,
    });

    const [formularioState, setFormularioState] = useState({
        nombre: '',
        nombreError: ''
    });

    const [loader, setLoader] = useState({
        loading: true
    });

    const [openModal, setopenModal] = useState(false)

    const openmodaleditar = (data) => {
        setState({ ...state, openEditar: true, fuenteSeleccionado: data });
    }
    const openmodaleliminar = (data) => {
        setState({ ...state, openEliminar: true, fuenteSeleccionado: data });
    }

    const handleCloseEditar = () => {
        setState({
            ...state, openEditar: false
        });
    }

    const handleCloseEliminar = () => {
        setState({
            ...state, openEliminar: false
        });
    }

    const { showNotification } = useContext(NotificacionContext)

    const columns = [
        {
            name: "fuente_solicitud",
            label: "NOMBRE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.fuentes[dataIndex].fuente_solicitud;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px' }} className="text-decoration-underline d-flex align-items-center">
                            <Tooltip title="EDITAR FUENTE">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openmodaleditar(state.fuentes[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                </div>
                            </Tooltip>

                            <Tooltip title="ELIMINAR FUENTE">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openmodaleliminar(state.fuentes[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineDelete /></IconUI>

                                </div>
                            </Tooltip>

                        </div>
                    </>
                )
            }
        },



    ];

    const handleNombre = (e) => {
        setFormularioState({ ...formularioState, nombre: e.target.value })
    }

    const handleFuenteSeleccionadoNombre = (e) => {
        const objeto = {
            id: state.fuenteSeleccionado.id,
            fuente_solicitud: e.target.value,
        }

        setState({ ...state, fuenteSeleccionado: objeto });

    }

    const validarFormulario = () => {
        const nombreError = formularioState.nombre === '';
        return !nombreError
    }

    const crearFuenteDeSolicitud = async () => {

        if (validarFormulario()) {
            setLoader(true)
            const Fuente = await services({
                method: 'POST', service: 'catFuentesSolicitudes/crear', body: {
                    fuente_solicitud: formularioState.nombre
                }
            })

            if (Fuente) {
                let fuentes = await services({ method: 'GET', service: `catFuentesSolicitudes/listar`, body: null });
                setState({ ...state, fuentes: fuentes.data.data, open: false })
                setFormularioState({ ...formularioState, nombre: '' })
                showNotification('success', 'Tipo de Fuentes', 'Se creo correctamente el tipo de fuente.', null, 4);
                setopenModal(false);
            } else {
                showNotification('error', 'ups... Algo sucedió', 'Error al agregar fuente', null, 4);
            }

        } else {

            showNotification('error', 'ups...Error ', 'Por favor llena todos los campos', null, 4);
        }

    }

    const editarfuente = async () => {
        if (state.fuenteSeleccionado.fuente_solicitud === "") {
            showNotification('error', 'Ups...Error ', 'Por favor llena todos los campos', null, 4);
        } else {
            setLoader(true)
            const EditFuente = await services({
                method: 'POST', service: `catFuentesSolicitudes/editar`, body: {
                    id: state.fuenteSeleccionado.id,
                    fuente_solicitud: state.fuenteSeleccionado.fuente_solicitud
                }
            })
            if (EditFuente) {
                setLoader(true)
                let fuentes = await services({ method: 'GET', service: `catFuentesSolicitudes/listar`, body: null });
                if (fuentes) {
                    setState({ ...state, fuentes: fuentes.data.data, openEditar: false })
                    showNotification('success', 'Tipo de Fuentes', 'Se editó correctamente el tipo de Fuente.', null, 4);
                    setLoader(false)
                } else {
                    showNotification('error', 'Ups...Error ', 'Ocurrió algo al editar', null, 4);
                }
            } else {
                setLoader(false)
            }
        }
    }

    const eliminarFuente = async () => {
        setLoader(true)
        const EliminarFuente = await services({ method: 'DELETE', service: `catFuentesSolicitudes/eliminar/${state.fuenteSeleccionado.id}` })
        if (EliminarFuente) {
            setLoader(true)
            let fuentes = await services({ method: 'GET', service: `catFuentesSolicitudes/listar`, body: null });
            if (fuentes) {
                setState({ ...state, fuentes: fuentes.data.data, openEliminar: false })
                showNotification('success', 'Tipo de Fuentes', 'Se eliminó correctamente el tipo de Fuente.', null, 4);
                setLoader(false)
            } else {
                showNotification('error', 'Ups...', 'Algo Ocurrió al Eliminar', null, 4)
                setLoader(false)
            }

        } else {
            setLoader(false)
        }
    }

    const mostrarFuentesDeSolicitud = async () => {

        let fuentes = await services({ method: 'GET', service: `catFuentesSolicitudes/listar`, body: null });

        if (fuentes) {
            setState({ ...state, fuentes: fuentes.data.data })
            setLoader(false)
        }else{
            showNotification('error', 'Ups...', 'Algo ocurrió no se pudo cargar la información', null, 4)
        }
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarFuentesDeSolicitud()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>

                {loader.loading &&
                    <LoaderComponent />
                }
                <div className='container'>
                    <Grid sx={{ marginTop: 3 }} container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                        <Grid item >
                            <ColorButton startIcon={<AddCircleOutlineIcon />} onClick={() => { setopenModal(true) }}>
                                Añadir Nueva Fuente
                            </ColorButton>
                        </Grid>
                    </Grid>
                </div>
                <DataTable
                    data={state.fuentes}
                    title={`TABLA FUENTES DE SOLICITUDES`}
                    columns={columns}
                />

                {/*Modal para Guardar */}
                <Modal
                    open={openModal}
                    onClose={() => setopenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>

                        <h3 className='TitulosProgramas mb-3'>
                            NUEVA FUENTE DE SOLICITUD
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={4}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    error={formularioState.nombreError}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE"
                                    variant="outlined"
                                    value={formularioState.nombre}
                                    onChange={handleNombre}
                                    onKeyPress={(event) => {
                                        onlyLetter(event, 55);
                                    }}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    crearFuenteDeSolicitud();
                                }}>
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Editar */}
                <Modal
                    open={state.openEditar}
                    onClose={handleCloseEditar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas mb-3'>
                            Editar fuente
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={state.fuenteSeleccionado.fuente_solicitud}
                                    onChange={handleFuenteSeleccionadoNombre}
                                    onKeyPress={(event) => {
                                        onlyLetter(event, 55);
                                    }}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarfuente();
                                }}>
                                    Editar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Eliminar */}
                <Modal
                    open={state.openEliminar}
                    onClose={handleCloseEliminar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style}>
                        <h3 className='TitulosProgramas  mb-5'>
                            ¿ESTÁ SEGURO DE ELIMINAR LA FUENTE?
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={4}
                        >

                            <Grid item xs={12}>
                                <h4>
                                    {state.fuenteSeleccionado.fuente_solicitud}
                                </h4>
                            </Grid>

                            <Grid margin="auto" item >
                                <ColorButton onClick={() => {
                                    eliminarFuente();
                                }}>
                                   Eliminar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

            </div>

        </>
    )
}

export default FuentesDeSolicitud

import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #661d40',
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    overflowY: "scroll",
    height: 'auto',
    borderRadius: "40px",
    scrollbarWidth: "none",
};

const DescripcionModal = ({ open, descripcion, onClose }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='contenedorModalUrs' sx={style2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p>{descripcion}</p>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default DescripcionModal;

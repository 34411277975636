import './styles/index.css';
import './styles/global.css';
import './styles/indexMobile.css';

import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";

import { React } from 'react';
import Login from './views/LoginView';
import Dashboard from './views/DashboardView';
import DashboardDep from './views/DashboardDepView';
import DashboardUser from './views/DashboardUserView';
import Firmas from './views/FirmanteView';
import FirmaUser from './views/FirmanteUserView';
import Folios from './views/FoliosView';
import Dependencias from './views/DependenciasView';
import Usuarios from './views/UsuariosView';
import Audiencia from './views/AudienciaView';
import AudienciaUser from './views/AudienciaUserView';
import Localidades from './views/Localidades';
import Titulos from './views/TitulosView';
import TipoCargo from './views/TipoCargoView';
import Ciudadanos from './views/CiudadanosView';
import CiudadanosUser from './views/CiudadanosUserView';
import SolicitudCiudadano from './views/SolicitudesCiudadanoView';
import SolicitudCUser from './views/SolicitudesCUserView';
import PeticionesCiudadano from './views/PeticionesCiudadanoView';
import PeticionesCUser from './views/PeticionesCUserView';
import Solicitudes from './views/SolicitudesView';
import SolicitudesUser from './views/SolicitudesUserView';
import Peticiones from './views/PeticionesView';
import PeticionesUser from './views/PeticionesUserView';
import PeticionesDep from './views/PeticionesDepView';
import FuentesDeSolicitud from './views/FuentesDeSolicitudView';
import TiposDePeticion from './views/TiposDePeticionView';
import HeaderComponent from './components/header_component';
import Footer from './components/footer_component';
import Error500 from './components/error_View';
import { useState,useEffect } from "react";
import { Menu } from "./components/menu_component"
import { GlobalContextProvider } from './context/global_context';
import { NotificacionContextProvider } from './context/notificacion_context';
import NotificacionUi from "./components/iu/notificacion_ui";
import PrivateRoute from './services/privateRoute';
import PrivateRouteDep from './services/privateRouteDep';
import PrivateRouteUser from './services/privateRouteUser';
import PeticionConsultaView from './views/PeticionConsultaView';
import CrearPeticionView from './views/crearPeticionView';
import InformacionPublicaView from './views/InformacionPublicaView';
import GeolocalizacionView from './views/GeolocalizacionView';
import { services } from './services/api';
// import Example from './views/HomeView';


const RouterApp = () => {

  let routes = useRoutes([

    { path: "/login", element: <Login /> },
    { path: "/consulta-peticion", element: <PeticionConsultaView /> },
    { path: "/crear-peticion", element: <CrearPeticionView /> },
    { path: "/aciudadana", element: <InformacionPublicaView /> },
    { path: "/firmas", element: <PrivateRoute> <Firmas /> </PrivateRoute> },
    { path: "/firmaUser", element: <PrivateRouteUser><FirmaUser /></PrivateRouteUser> },
    // { path: "/ejemplo", element: <Example /> },
    { path: "/inicio", element: <PrivateRoute> <Dashboard /> </PrivateRoute> },
    { path: "/inicioDep", element: <PrivateRouteDep><DashboardDep /></PrivateRouteDep> },
    { path: "/inicioUser", element: <PrivateRouteUser><DashboardUser /></PrivateRouteUser> },
    { path: "/folios", element: <PrivateRoute> <Folios /> </PrivateRoute> },
    { path: "/ciudadanos", element: <PrivateRoute> <Ciudadanos /> </PrivateRoute> },
    { path: "/ciudadanosUsuario", element: <PrivateRouteUser><CiudadanosUser /></PrivateRouteUser> },
    { path: "/solicitudCiudadano/:id", element: <PrivateRoute> <SolicitudCiudadano /> </PrivateRoute> },
    { path: "/solicitudCiudadanoUsuario/:id", element: <PrivateRouteUser><SolicitudCUser /></PrivateRouteUser> },
    { path: "/peticionesCiudadano/:id", element: <PrivateRoute> <PeticionesCiudadano /> </PrivateRoute> },
    { path: "/peticionesCiudadanoUsuario/:id", element: <PrivateRouteUser><PeticionesCUser /></PrivateRouteUser> },
    { path: "/solicitudes", element: <PrivateRoute> <Solicitudes /> </PrivateRoute> },
    { path: "/solicitudesUsuario", element: <PrivateRouteUser><SolicitudesUser /></PrivateRouteUser> },
    { path: "/peticiones", element: <PrivateRoute> <Peticiones /> </PrivateRoute> },
    { path: "/peticionesDep", element: <PrivateRouteDep><PeticionesDep /></PrivateRouteDep> },
    { path: "/peticionesUsuario", element: <PrivateRouteUser><PeticionesUser /></PrivateRouteUser> },
    { path: "/dependencias", element: <PrivateRoute> <Dependencias /> </PrivateRoute> },
    { path: "/usuarios", element: <PrivateRoute> <Usuarios /> </PrivateRoute> },
    { path: "/audiencia", element: <PrivateRoute><Audiencia /></PrivateRoute> },
    { path: "/audienciaUser", element: <PrivateRouteUser><AudienciaUser /></PrivateRouteUser> },
    { path: "/titulos", element: <PrivateRoute> <Titulos /> </PrivateRoute> },
    { path: "/localidades", element: <PrivateRoute> <Localidades /> </PrivateRoute> },
    { path: "/fuentesSolicitud", element: <PrivateRoute> <FuentesDeSolicitud /> </PrivateRoute> },
    { path: "/tiposCargo", element: <PrivateRoute> <TipoCargo /> </PrivateRoute> },
    { path: "/tiposPeticion", element: <PrivateRoute> <TiposDePeticion /> </PrivateRoute> },
    {path: "/geolocalizacion",element:<GeolocalizacionView />},
    { path: "/", element: <Login /> },
    { path: "/*", element: <Error500 /> }

  ]);
  return routes;
}



function App() {

  const [state, setState] = useState({
    showMenu: false
  })

  // const [token, setToken] = useState(localStorage.getItem('token'));

  // let token = localStorage.getItem('token')
  const [change, setChange] = useState(true)

  const checkAndUpdateToken = async () => {
    let token = window.localStorage.getItem('token')
    if (token || token === undefined) {
      let currentTime = Math.floor(Date.now() / 1000); // Fecha actual en segundos
      let tokenExp = JSON.parse(atob(token.split('.')[1])).exp; // Obtiene la fecha de expiración del token

  
        if ((tokenExp - 50) < currentTime) {
       
          const refreshToken = await services({ method: 'GET', service: 'refreshToken' })
          
          // setToken(refreshToken.data.token);
          localStorage.setItem('token', refreshToken.data.token);
          // setChange(false)
        }
    }
  };

  useEffect(() => {
    document.addEventListener("click", checkAndUpdateToken);

    return () => {
      document.removeEventListener("click", checkAndUpdateToken);
    };
  }, [checkAndUpdateToken]);

  return (
    <div className="App">
      <GlobalContextProvider>
        <NotificacionContextProvider>
          <main id="mainContent">

            <Router>
              <HeaderComponent onClose={() => { setState({ ...state, showMenu: true }) }} />
              {state.showMenu ?
                <Menu onClose={() => { setState({ ...state, showMenu: false }) }} />
                :
                null
              }
              <NotificacionUi />
              <div className='bg-rosaClarito bk-img mainContent' >
                <RouterApp />
              </div>
              <Footer />
              <div className='backgrounPlaca w-100 '></div>
            </Router>

          </main>

        </NotificacionContextProvider>
      </GlobalContextProvider >
    </div>
  );
}

export default App;
import React, { useEffect, useState, useContext } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
  Pane,
  GeoJSON,
} from "react-leaflet";
import { Box, Grid, Card, CardContent, Modal, IconButton } from "@mui/material";
import { Icon } from "leaflet";
import PublicIcon from "@mui/icons-material/Public";
import "leaflet/dist/leaflet.css";
import markerDggdRojo from "../assets/img/Loc. Atención CiudadanaRojo.png";
import markerDggd from "../assets/img/Loc. Atención Ciudadana.png";
import { services } from "../services/api";
import logoAudiencias from "../assets/img/Atención Ciudadana.png";
import LoaderComponent from "../components/admin/LoaderComponent";
import { GlobalContext } from "../context/global_context";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import UndoIcon from "@mui/icons-material/Undo";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import michoacanGeoJson from "./michoacan.json";

// const outer = [

//     // [18.927, -100.717],
//     // [18.927, -100.717],

//     // [52.505, 29.09],
//   ]

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: "#661d40",
  //backgroundColor: ' #8d949e',
  "&:hover": {
    backgroundColor: "#661d40",
    //backgroundColor: ' #8d949e',
  },
  borderRadius: "40px",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const GeolocalizacionView = ({ data }) => {
  const [loader, setLoader] = useState({
    loading: false,
  });

  const [state, setState] = useState({
    open: false,
    dependenciasFrecuentes: {},
  });

  const handleCloseModal = () => {
    setState({ ...state, open: false });
  };

  const geoJsonStyle = {
    color: "#f5cba7", // Color del contorno
    weight: 3, // Grosor de la línea
    fillOpacity: 0.1, // Opacidad de relleno (ajusta según tus preferencias)
  };

  const { formularioGeo } = useContext(GlobalContext);
  const goTo = useNavigate();

  const iconDggdRojo = new Icon({
    iconUrl: markerDggdRojo,
    iconSize: [20, 30],
  });
  const iconDggd = new Icon({
    iconUrl: markerDggd,
    iconSize: [20, 30],
  });

  const rol = localStorage.getItem("rol");
  console.log(rol);

  console.log(formularioGeo, "que trae");

  const getCountMunicipio = async () => {
    // const {data, status, errros} = await services({ method: 'GET', service: `https://www.openstreetmap.org/api/0.6/relation/2340636/`, body: { usuario: state.usuario, password: state.pass } })
    // console.log(data, status, errros)
  };

  const obtenerInfo = (municipioId) => {
    const index = formularioGeo.data.conteo_municipio.filter(
      (municipio) => municipio.id === municipioId
    );
    setState({
      ...state,
      open: true,
      dependenciasFrecuentes: index[0].dependencias_mas_frecuente,
    });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getCountMunicipio();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="container pt-5">
      {loader.loading && <LoaderComponent />}
      <div className="my-4">
        <ColorButton
          startIcon={<UndoIcon className="ms-2" />}
          onClick={() => {
            if (rol === "ADMINISTRADOR") {
              goTo("/peticiones");
            } else {
              goTo("/peticionesUsuario");
            }
          }}
        >
          Regresar
        </ColorButton>
      </div>
      <Card>
        <CardContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid
                className="text-center d-flex ms-2 mt-2 "
                item
                md={4}
                xs={12}
              >
                <h3 className="TitulosCarreras">
                  Geolocalización
                  <PublicIcon />
                </h3>
              </Grid>
              <Grid className="d-flex justify-content-end" item md={12} xs={12}>
                <img
                  src={markerDggdRojo}
                  alt="Avatar"
                  style={{ width: "20px", height: "auto", marginRight: "10px" }}
                />
                <div className="me-3">Municipios con más peticiones</div>
                {/* <img src={markerDggd} alt="Avatar" style={{ width: '20px', height: 'auto', marginRight: '10px' }} />
                                Municipio Secundario */}
              </Grid>
            </Grid>
            <hr />

            <Grid container className="my-5 d-flex justify-content-center">
              <Grid className="text-center mb-2 me-3" item md={4} xs={12}>
                <Card className="card--GuindaSFA">
                  <b>Número de Ciudadanos:</b>
                  <br />
                  {formularioGeo.data?.total_ciudadanos ?? "N/A"}
                </Card>
              </Grid>

              <Grid className="text-center mb-2" item md={4} xs={12}>
                <Card className="card--GuindaSFA">
                  <b>Número de Peticiones:</b>
                  <br />
                  {formularioGeo.data?.total_peticiones ?? "N/A"}
                </Card>
              </Grid>
            </Grid>
            <hr />

            <Grid className="container mt-2 my-5">
              <Grid item md={12} xs={12}>
                <MapContainer
                  className="leaflet-container"
                  center={[19.279704, -101.742209]}
                  zoom={9}
                  zoomControl={false}
                >
                  <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png"
                    attribution="©OpenStreetMap, ©CartoDB"
                  />

                  <Pane name="labels">
                    <TileLayer
                      url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png"
                      attribution="©OpenStreetMap, ©CartoDB"
                      pane="labels"
                    />
                    <GeoJSON data={michoacanGeoJson} style={geoJsonStyle} />
                  </Pane>
                  {/* <Pane name="purple-rectangle">
                                        <Rectangle bounds={outer} pathOptions={{ color: 'purple' }} />
                                    </Pane> */}
                  <ZoomControl position="bottomright" />

                  {formularioGeo.data &&
                  formularioGeo.data.length !== 0 &&
                  formularioGeo.data.conteo_municipio &&
                  formularioGeo.data.conteo_municipio.length > 0 ? (
                    formularioGeo.data.conteo_municipio.map(
                      (municipio, index) => {
                        if (municipio.id != null) {
                          let icon = index < 14 ? iconDggdRojo : iconDggd;
                          return (
                            <Marker
                              key={municipio.id}
                              icon={icon}
                              position={[municipio.latitud, municipio.longitud]}
                            >
                              <Popup>
                                <Grid container>
                                  <Grid
                                    className="text-center mb-2"
                                    item
                                    md={12}
                                    xs={12}
                                    style={{ height: "70px" }}
                                  >
                                    <img
                                      src={logoAudiencias}
                                      alt="Avatar"
                                      style={{ width: "55px", height: "auto" }}
                                    />
                                  </Grid>
                                  <div className="text-center text-guinda mb-4 mx-auto">
                                    <span className="remarcado">
                                      <b>{municipio.municipio}</b>
                                    </span>
                                  </div>
                                  <div className="text-left text-guinda mb-2">
                                    <span className="remarcado">
                                      <b>TOTAL CIUDADANOS:</b>
                                    </span>
                                    <b>
                                      {municipio.total_municipio_ciudadanos}
                                    </b>
                                  </div>
                                  <div className="text-left text-guinda mb-2">
                                    <span className="remarcado">
                                      <b>TOTAL PETICIONES:</b>
                                    </span>
                                    <b>
                                      {municipio.total_municipio_peticiones}
                                    </b>
                                  </div>
                                </Grid>
                                <Grid className="d-flex justify-content-center">
                                  <ColorButton
                                    onClick={() => {
                                      obtenerInfo(municipio.id);
                                    }}
                                  >
                                    Ver Dependencias
                                  </ColorButton>
                                </Grid>
                              </Popup>
                            </Marker>
                          );
                        }
                        return null;
                      }
                    )
                  ) : (
                    <p>No hay datos de municipios disponibles.</p>
                  )}
                </MapContainer>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>

      <Modal
        open={state.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3 className="TitulosProgramas mx-auto">
            DEPENDENCIAS CON MÁS PETICIONES
          </h3>
          <IconButton
            className={
              "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
            }
            onClick={() => handleCloseModal()}
          >
            <Close />
          </IconButton>
          {console.log(state.dependenciasFrecuentes)}
          <ul>
            {Object.entries(state.dependenciasFrecuentes).map(
              ([dependencia, valor]) => (
                <li key={dependencia}>
                  {dependencia}: <strong>{valor}</strong>
                </li>
              )
            )}
          </ul>
        </Box>
      </Modal>
    </div>
  );
};

export default GeolocalizacionView;

import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../components/DataTableComponents';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Tooltip } from '@mui/material';
import IconUI from '../components/iu/icon_ui';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField} from '@mui/material';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';


const Titulos = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        // border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "auto",
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const [state, setState] = useState({
        titulos: [],
        tituloSeleccionado: {},
        openEditar: false,
        openEliminar: false,
    });

    const [formularioState, setFormularioState] = useState({
        nombre: '',
        nombreError: ''
    });

    const [loader, setLoader] = useState({
        loading: true
    });

    const [openModal, setopenModal] = useState(false)

    const openmodaleditar = (data) => {
        setState({ ...state, openEditar: true, tituloSeleccionado: data });
    }
    const openmodaleliminar = (data) => {
        setState({ ...state, openEliminar: true, tituloSeleccionado: data });
    }

    const handleCloseEditar = () => {
        setState({
            ...state, openEditar: false
        });
    }

    const handleCloseEliminar = () => {
        setState({
            ...state, openEliminar: false
        });
    }

    const { showNotification } = useContext(NotificacionContext)

    const columns = [
        {
            name: "tipo_titulo",
            label: "NOMBRE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.titulos[dataIndex].tipo_titulo;
                    return (<div className=' d-flex align-items-center' style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px' }} className="text-decoration-underline d-flex align-items-center">
                            <Tooltip title="EDITAR TÍTULO">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openmodaleditar(state.titulos[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                </div>
                            </Tooltip>

                            <Tooltip title="ELIMINAR TÍTULO">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openmodaleliminar(state.titulos[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineDelete /></IconUI>
                                </div>
                            </Tooltip>
                        </div>
                    </>
                )
            }
        },



    ];

    const handleNombre = (e) => {
        setFormularioState({ ...formularioState, nombre: e.target.value.toUpperCase() })
    }

    const handleTituloSeleccionadoNombre = (e) => {
        const objeto = {
            id: state.tituloSeleccionado.id,
            tipo_titulo: e.target.value,
        }

        setState({ ...state, tituloSeleccionado: objeto });

    }

    const validarFormulario = () => {
        const nombreError = formularioState.nombre === '';
        return !nombreError
    }

    const crearTitulo = async () => {

        if (validarFormulario()) {
            setLoader({ loading: true })
            const titulo = await services({
                method: 'POST', service: 'catTiposTitulos/crear', body: {
                    tipo_titulo: formularioState.nombre
                }
            })

            if (titulo.status === 200) {
                let titulos = await services({ method: 'GET', service: `catTiposTitulos/listar`, body: null })
                if (titulos) {
                    setLoader({ loading: false })
                    setFormularioState({ ...formularioState, nombre: '' })
                    setState({ ...state, titulos: titulos.data.data })
                    showNotification('success', 'Título', 'Agregado con Éxito', null, 4);
                    setopenModal(false);
                }
            } else {
                setLoader({ loading: false })
                showNotification('error', 'ups... Algo sucedió', 'Error al agregar el título', null, 4);
                setFormularioState({ ...formularioState, nombre: '' })
                setopenModal(false);
            }

        } else {
            showNotification('error', 'ups...Error ', 'Por favor llena todos los campos', null, 4);
        }

    }

    const editarTitulo = async () => {
        if (state.tituloSeleccionado.tipo_titulo === "") {
            showNotification('error', 'Ups... ', 'Favor de llenar los campos', null, 4);
        } else {
            setLoader({ loading: true })
            const EditTitulo = await services({
                method: 'POST', service: `catTiposTitulos/editar`, body: {
                    id:state.tituloSeleccionado.id,
                    tipo_titulo: state.tituloSeleccionado.tipo_titulo?.toUpperCase()
                }
            })
            if (EditTitulo.status === 200) {
                let titulos = await services({ method: 'GET', service: `catTiposTitulos/listar`, body: null })
                if (titulos.status === 200) {
                    setState({ ...state, titulos: titulos.data.data, openEditar: false })
                    showNotification('success', 'Título', 'Editado con Éxito', null, 4);
                    setLoader({ loading: false })
                } else {
                    setLoader({ loading: false })
                    showNotification('error', 'Ups... ', 'Error al Actualizar Títulos', null, 4);
                }
            } else {
                setState({ ...state, openEditar: false })
                setLoader({ loading: false })
                showNotification('error', 'Ups... ', 'Error al Editar Título', null, 4);
            }
        }
    }

    const eliminarTitulo = async () => {
        setLoader(true)
        const EliminarTitulo = await services({ method: 'DELETE', service: `catTiposTitulos/eliminar/${state.tituloSeleccionado.id}` })
        if (EliminarTitulo) {
            setLoader(true)
            let titulos = await services({ method: 'GET', service: `catTiposTitulos/listar`, body: null })
            if (titulos) {
                setState({ ...state, titulos: titulos.data.data, openEliminar: false })
                showNotification('success', 'Título Eliminado con Éxito', '', null, 4);
                setLoader(false)
            } else {
                setLoader(false)
                showNotification('error', 'Ups...', 'Error al Eliminar el Título', null, 4);
            }
        } else {
            setLoader(false)
            showNotification('error', 'Ups...', 'Error al Eliminar el Título', null, 4);
        }
    }

    const mostrarTitulos = async () => {

        let titulos = await services({ method: 'GET', service: `catTiposTitulos/listar`, body: null });

        if (titulos) {
            setState({ ...state, titulos: titulos.data.data })
        }
        setLoader(false)
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarTitulos()
        }

        return () => {
            isMounted = false
        }
         // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>

                {loader.loading &&
                    <LoaderComponent />
                }
                <div className='container'>
                    <Grid sx={{ marginTop: 3 }} container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                        <Grid item >
                            <ColorButton sx={{backgroundColor: '#6A0F49'}} startIcon={<AddCircleOutlineIcon />} onClick={() => { setopenModal(true) }}>
                                Añadir Nuevo Título
                            </ColorButton>
                        </Grid>
                    </Grid>
                </div>
                <DataTable
                    data={state.titulos}
                    title={`TABLA DE TÍTULOS`}
                    columns={columns}
                />

                {/*Modal para Guardar */}
                <Modal
                    open={openModal}
                    onClose={() => setopenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>

                        <h3 className='TitulosProgramas mb-3'>
                            NUEVO TÍTULO
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={4}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    error={formularioState.nombreError}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE"
                                    variant="outlined"
                                    value={formularioState.nombre.toUpperCase()}
                                    onChange={handleNombre}

                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    crearTitulo();
                                }}>
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Editar */}
                <Modal
                    open={state.openEditar}
                    onClose={handleCloseEditar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas mb-3'>
                            Editar Títulos
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="Nombre"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={state.tituloSeleccionado.tipo_titulo}
                                    onChange={handleTituloSeleccionadoNombre}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarTitulo();

                                }}>
                                    Editar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Eliminar */}
                <Modal
                    open={state.openEliminar}
                    onClose={handleCloseEliminar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style}>
                        <h3 className='TitulosProgramas  mb-5'>
                            ¿ESTÁ SEGURO DE ELIMINAR EL TÍTULO?
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={4}
                        >

                            <Grid item xs={12}>
                                <h4>
                                    {state.tituloSeleccionado.tipo_titulo}
                                </h4>
                            </Grid>

                            <Grid margin="auto" item >
                                <ColorButton onClick={() => {
                                    eliminarTitulo();

                                }}>
                                    Eliminar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

            </div>

        </>

    )
}





export default Titulos;

import React from 'react';

import Container from '@mui/material/Container';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Error500 = () => {
    const navigate = useNavigate();
    useEffect(() => {
      let isMounted = true
      if(isMounted){
        navigate("/login");
      }
    
      return () => {
        isMounted = false
      }
      // eslint-disable-next-line
    }, [])
    

    return (
        <Container>
            <div className='d-flex justify-content-center align-items-center vh-100'>
                <img alt='mensajeError' src='mensajeError.png'></img>
            </div>
        </Container> 
    );
}



export default Error500;
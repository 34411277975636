import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../components/DataTableComponents';
import IconUI from '../components/iu/icon_ui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Tooltip } from '@mui/material';
import LoaderComponent from '../components/admin/LoaderComponent';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Modal, TextField, Select, InputLabel, MenuItem, IconButton, InputAdornment, Autocomplete, OutlinedInput, FormControl, Chip } from '@mui/material';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { maxLengthLimitInput, onlyNumber } from '../utils/inputsRules';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';



const Usuarios = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        // border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 600,
        borderRadius: "40px",
        scrollbarWidth: "none",
    };

    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 'auto',
        borderRadius: "40px",
        scrollbarWidth: "none",
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    // state del componente
    const [state, setState] = useState({
        usuarios: [],
        usuarioSeleccionado: {},
        openModalCrear: false,
        openModalEditar: false,
        openModalEliminar: false,
    });

    const [titulosState, setTitulos] = useState({
        data: []
    })

    const [dependenciasState, setDependencias] = useState({
        data: []
    })

    const permisos = [
        {
            id: 8,
            nombre: 'SOLO_LECTURA'
        },
        {
            id: 1,
            nombre: 'REGISTRO'
        },
        {
            id: 2,
            nombre: 'BORRAR_ASUNTO'
        },
        {
            id: 3,
            nombre: 'EXPORTACIÓN'
        },
        {
            id: 4,
            nombre: 'RESTCOORDIVA'
        },
        {
            id: 5,
            nombre: 'AUDIENCIA'
        },
        {
            id: 6,
            nombre: 'EXPORTAR_AUDIENCIA'
        },
        {
            id: 7,
            nombre: 'FIRMAR_DOCUMENTOS'
        },
        {
            id:9,
            nombre: 'REVISAR_DOCUMENTOS'
        },
        {
            id:10,
            nombre:'GEOLOCALIZACION'
        }

    ]

    // state  Formularios
    const [formularioState, formularioSetState] = useState({
        nombre: '',
        usuario: '',
        telefono: '',
        email: '',
        permisos: [],
        password_desencriptada: '',
        cat_tipo_titulo_id: '',
        dependencia_id: '',
        tipo_rol_id: '',
    });

    const [errors, setErrors] = useState({});

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword })
    }

    //state del loading
    const [loader, setLoader] = useState({
        loading: true
    });

    const { showNotification } = useContext(NotificacionContext)


    //state del modal de agregar nuevo usuario
    const openModalCrear = () => {
        setState({ ...state, openModalCrear: true });
    }
    const handleCloseCrear = () => {
        setState({ ...state, openModalCrear: false });
        limpiarFormulario();
    }

    //abrir y cerrar modal de eliminar usuario
    const openmodaleliminar = (data) => {
        setState({ ...state, openModalEliminar: true, usuarioSeleccionado: data });
    }

    const handleCloseEliminar = () => {
        setState({
            ...state, openModalEliminar: false
        });
    }

    //abrir y cerrar modal de editar usuario
    const openModalEditar = (data) => {
        setState({ ...state, openModalEditar: true, usuarioSeleccionado: data });
        setPermisos(data.permisos);
    }

    const handleCloseEditar = () => {
        setState({
            ...state, openModalEditar: false
        });
        setPermisos([]);
        setErrors({});

    }

    //columnas y logica de la tabla
    const columns = [
        {
            name: "nombre",
            label: "NOMBRE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.usuarios[dataIndex].nombre;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "usuario",
            label: "USUARIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.usuarios[dataIndex].usuario;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "email",
            label: "CORREO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let titular = state.usuarios[dataIndex].email;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{titular}</div>)
                }
            }
        },
        {
            name: "dependencia",
            label: "DEPENDENCIA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let coordinador = state.usuarios[dataIndex].dependencia;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{coordinador}</div>)
                }
            }
        },
        {
            name: "tipo_rol",
            label: "ROL",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let rol = state.usuarios[dataIndex].tipo_rol;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{rol}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px' }} className="text-decoration-underline d-flex align-items-center">
                            <Tooltip title="EDITAR USUARIO">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openModalEditar(state.usuarios[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                </div>
                            </Tooltip>

                            <Tooltip title="ELIMINAR USUARIO">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openmodaleliminar(state.usuarios[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineDelete /></IconUI>

                                </div>
                            </Tooltip>

                        </div>
                    </>
                )
            }
        },



    ];

    // manejo del formulario nuevo usuario
    const handleNombre = (e) => {
        formularioSetState({ ...formularioState, nombre: e.target.value.toUpperCase() })
    }
    const handleUsuario = (e) => {
        formularioSetState({ ...formularioState, usuario: e.target.value.toUpperCase() })
    }
    const handleTipoRol = (e) => {
        formularioSetState({ ...formularioState, tipo_rol_id: e.target.value })
        setPermisos([]);
    }
    const handleTelefono = (e) => {
        formularioSetState({ ...formularioState, telefono: e.target.value })
    }
    const handleEmail = (e) => {
        formularioSetState({ ...formularioState, email: e.target.value.toUpperCase() })
    }
    const handleDependencia = (e) => {
        formularioSetState((formularioState) => ({ ...formularioState, dependencia_id: e ? e.id : null, }));
    }
    const handleTitulo = (e) => {
        formularioSetState({ ...formularioState, cat_tipo_titulo_id: e.target.value })
    }

    const [permisosState, setPermisos] = useState([])

    const handleChangePermisos = (event) => {
        const {
            target: { value },
        } = event;
        setPermisos(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    // fin manejo del formulario nuevo usuario

    // manejo de formulario editar usuario

    const handleSeleccionadoNombre = (e) => {
        setState({ ...state, usuarioSeleccionado: { ...state.usuarioSeleccionado, nombre: e.target.value } })
    }
    const handleSeleccionadoUsuario = (e) => {
        setState({ ...state, usuarioSeleccionado: { ...state.usuarioSeleccionado, usuario: e.target.value } })
    }
    const handleSeleccionadoTipoRol = (e) => {
        setState({ ...state, usuarioSeleccionado: { ...state.usuarioSeleccionado, tipo_rol_id: e.target.value } })
    }
    const handleSeleccionadoTelefono = (e) => {
        setState({ ...state, usuarioSeleccionado: { ...state.usuarioSeleccionado, telefono: e.target.value } })
    }
    const handleSeleccionadoEmail = (e) => {
        setState({ ...state, usuarioSeleccionado: { ...state.usuarioSeleccionado, email: e.target.value } })
    }
    const handleSeleccionadoPass = (e) => {
        setState({ ...state, usuarioSeleccionado: { ...state.usuarioSeleccionado, password_desencriptada: e.target.value } })
    }
    const handleSeleccionadoChange = (event) => {
        const {
            target: { value },
        } = event;
        setPermisos(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleSeleccionadoDependencia = (e) => {
        setState({ ...state, usuarioSeleccionado: { ...state.usuarioSeleccionado, dependencia_id: e ? e.id : null } })
    }
    const handleSeleccionadoTitulo = (e) => {
        setState({ ...state, usuarioSeleccionado: { ...state.usuarioSeleccionado, cat_tipo_titulo_id: e.target.value } })
    }

    //manejo de formulario editar usuario

    //validaciones del formulario
    const validarFormulario = () => {
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let newErrors = {};
        if (!formularioState.nombre) { newErrors.nombre = 'Este Campo es Requerido' }
        if (!formularioState.usuario) { newErrors.usuario = 'Este Campo es Requerido' }
        if (!formularioState.dependencia_id) { newErrors.dependencia_id = 'Este Campo es Requerido' }
        if (!formularioState.tipo_rol_id) { newErrors.tipo_rol_id = 'Este Campo es Requerido' }
        if (!formularioState.email) { newErrors.email = 'Este Campo es Requerido' }
        if (formularioState.tipo_rol_id === 2 && permisosState.length === 0) {
            newErrors.permisos = 'Este Campo es Requerido'
            showNotification('error', 'Ups... ', 'Favor de Agregar al menos un permiso', null, 4);
        }

        if (formularioState.telefono !== "" && formularioState.telefono.length < 10) { newErrors.telefono = 'Favor poner un número de 10 dígitos' }
        if (formularioState.email !== "") {
            let valido = formularioState.email.match(regexEmail)
            if (!valido) {
                newErrors.email = 'Ingrese un email válido'
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;

    }

    const validarEditar = () => {
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let newErrors = {};
        if (!state.usuarioSeleccionado.nombre) { newErrors.nombre = 'Este Campo es Requerido' }
        if (!state.usuarioSeleccionado.usuario) { newErrors.usuario = 'Este Campo es Requerido' }
        if (!state.usuarioSeleccionado.dependencia_id) { newErrors.dependencia_id = 'Este Campo es Requerido' }
        if (!state.usuarioSeleccionado.tipo_rol_id) { newErrors.tipo_rol_id = 'Este Campo es Requerido' }
        if (!state.usuarioSeleccionado.password_desencriptada) { newErrors.password_desencriptada = 'Este Campo es Requerido' }
        if (state.usuarioSeleccionado.telefono !== null && state.usuarioSeleccionado.telefono !== "" && state.usuarioSeleccionado.telefono.length < 10) { newErrors.telefono = 'Favor poner un número de 10 dígitos' }
        if (state.usuarioSeleccionado.tipo_rol_id === 2 && permisosState.length === 0) {
            newErrors.permisos = 'Este Campo es Requerido'
            showNotification('error', 'Ups... ', 'Favor de Agregar al menos un permiso', null, 4);
        }
        if (state.usuarioSeleccionado.email !== "") {
            let valido = state.usuarioSeleccionado.email.match(regexEmail)
            if (!valido) {
                newErrors.email = 'Ingrese un email válido'
            }
        } else {
            newErrors.email = 'Este Campo es Requerido'
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;

    }

    //Peticion de usuarios
    const mostrarUsuarios = async () => {
        setLoader({ ...loader, loading: true });
        let usuarios = await services({ method: 'GET', service: `usuarios/listar`, body: null });
        let dependencias = await services({ method: 'GET', service: `dependencias/listar`, body: null });
        let titulos = await services({ method: 'GET', service: `catTiposTitulos/listar`, body: null });



        if (usuarios.status === 200 && dependencias.status === 200 && titulos.status === 200) {
            setState({ ...state, usuarios: usuarios.data.data });
            setTitulos({ ...titulosState, data: titulos.data.data });
            setDependencias({ ...dependenciasState, data: dependencias.data.data });
            setLoader({ ...loader, loading: false });
        } else {
            setLoader({ ...loader, loading: false });
            showNotification('error', 'Usuarios', 'No se han podido cargar los usuarios', null, 4);
        }
    }


    //limpiar formulario
    const limpiarFormulario = () => {
        formularioSetState({
            ...formularioState,
            nombre: '',
            usuario: '',
            apellido_paterno: '',
            apellido_materno: '',
            telefono: '',
            email: '',
            cat_tipo_titulo_id: '',
            dependencia_id: '',
            tipo_rol_id: '',
        });
        setPermisos([]);
    }

    //Crear Usuario Nuevo
    const crearUsuario = async () => {
        setLoader({ ...loader, loading: true })
        if (validarFormulario()) {
            const usuario = await services({
                method: 'POST', service: 'usuarios/crear', body: {
                    nombre: formularioState.nombre,
                    usuario: formularioState.usuario,
                    telefono: formularioState.telefono,
                    email: formularioState.email,
                    cat_tipo_titulo_id: formularioState.cat_tipo_titulo_id,
                    dependencia_id: formularioState.dependencia_id,
                    tipo_rol_id: formularioState.tipo_rol_id,
                    permisos: permisosState
                }
            })
            if (usuario.status === 200) {
                const usuarios = await services({ method: 'GET', service: `usuarios/listar`, body: null })
                if (usuarios.status === 200) {
                    setState({ ...state, usuarios: usuarios.data.data, openModalCrear: false })
                    limpiarFormulario();
                    showNotification('success', 'Usuario', 'Se ha Agregado con Éxito', null, 4);
                    setLoader({ ...loader, loading: false })
                } else {
                    setLoader({ ...loader, loading: false })
                    showNotification('error', 'Ups... Algo sucedió', usuarios.data.message, null, 4);

                }
            } else {
                showNotification('error', 'Ups... Algo sucedió', usuario.data.message, null, 4);
                handleCloseCrear();
                setLoader({ ...loader, loading: false })
                limpiarFormulario();
            }
        }
        else {
            // showNotification('error', 'ups...Error ', 'Por favor llena todos los campos', null, 4);
            showNotification('error', 'ups...Error ', 'Por favor llena todos los campos', null);
            setLoader({ ...loader, loading: false })
        }

    }

    //editar Usuario Nuevo
    const editarUsuario = async () => {
        setLoader({ ...loader, loading: true })
        if (validarEditar()) {
            const usuario = await services({
                method: 'POST', service: `usuarios/editar`, body: {
                    id: state.usuarioSeleccionado.id,
                    nombre: state.usuarioSeleccionado.nombre?.toUpperCase(),
                    usuario: state.usuarioSeleccionado.usuario?.toUpperCase(),
                    telefono: state.usuarioSeleccionado.telefono,
                    email: state.usuarioSeleccionado.email?.toUpperCase(),
                    password: state.usuarioSeleccionado.password_desencriptada,
                    cat_tipo_titulo_id: state.usuarioSeleccionado.cat_tipo_titulo_id,
                    dependencia_id: state.usuarioSeleccionado.dependencia_id,
                    tipo_rol_id: state.usuarioSeleccionado.tipo_rol_id,
                    permisos: permisosState
                }
            })
            if (usuario.status === 200) {
                const usuarios = await services({ method: 'GET', service: `usuarios/listar`, body: null })
                if (usuarios.status === 200) {
                    setState({ ...state, usuarios: usuarios.data.data, openModalEditar: false })
                    showNotification('success', 'Usuario', 'Editado con Éxito', null, 4);
                    setLoader({ ...loader, loading: false })

                } else {
                    showNotification('error', 'Ups... Algo Sucedió', 'Error al Editar Usuario', null, 4);
                    setState({ ...state, openModalEditar: false })
                    setLoader({ ...loader, loading: false })

                }
            } else {
                setLoader({ ...loader, loading: false })

                setState({ ...state, openModalEditar: false })
                showNotification('error', 'Ups... Algo Sucedió', 'Error al Editar Usuario', null, 4);
            }
        } else {
            showNotification('error', 'Favor de Revisar', 'Los campos selecionados', null, 4);
            setLoader({ ...loader, loading: false })
        }
    }

    //eliminarUsuario
    const eliminarUsuario = async () => {
        setLoader({ ...loader, loading: true })
        const eliminar = await services({ method: 'POST', service: `usuarios/eliminar`, body: { id: state.usuarioSeleccionado.id } })
        if (eliminar.status === 200) {
            let usuarios = await services({ method: 'GET', service: `usuarios/listar`, body: null })
            if (usuarios.status === 200) {
                setState({ ...state, usuarios: usuarios.data.data, openModalEliminar: false })
                showNotification('success', 'Usuario', 'Eliminado con Éxito', null, 4);
                setLoader({ ...loader, loading: false })

            } else {
                setLoader({ ...loader, loading: false })

                setState({ ...state, openModalEliminar: false })
                showNotification('error', 'ups... Algo sucedió', 'Error al Eliminar Usuario', null, 4);
            }

        } else {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openModalEliminar: false })
            showNotification('error', 'ups... Algo sucedió', 'Error al Eliminar Usuario', null, 4);
        }
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarUsuarios()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>
                {loader.loading &&
                    <LoaderComponent />
                }
                <div className='container'>
                    <Grid sx={{ marginTop: 3 }} container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                        <Grid item >
                            <ColorButton  startIcon={<AddCircleOutlineIcon />} onClick={() => { openModalCrear() }}>
                                AÑADIR NUEVO USUARIO
                            </ColorButton>
                        </Grid>
                    </Grid>
                </div>
                <DataTable
                    data={state.usuarios}
                    title={`TABLA DE USUARIOS`}
                    columns={columns}
                />
                {/* Modal para crear usuario */}
                <Modal
                    open={state.openModalCrear}
                    onClose={() => handleCloseCrear()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            NUEVO USUARIO
                        </h3>
                        <hr />
                        <h5>DATOS GENERALES</h5>
                        <hr />
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE"
                                    variant="outlined"
                                    error={!!errors.nombre}
                                    helperText={errors.nombre || ''}
                                    value={formularioState.nombre}
                                    onChange={handleNombre}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="TÍTULO"
                                    variant="outlined"
                                    select
                                    onChange={handleTitulo}
                                    value={formularioState.cat_tipo_titulo_id}
                                >
                                    {titulosState.data && titulosState.data.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.tipo_titulo}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <hr />
                            <hr />
                            <h5 className='ms-4 mt-3'>USUARIO DEL SISTEMA</h5>
                            <hr />
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE DE USUARIO"
                                    variant="outlined"
                                    error={!!errors.usuario}
                                    helperText={errors.usuario || ''}
                                    value={formularioState.usuario}
                                    onChange={handleUsuario}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-TipoUsuario"
                                    label="TIPO DE USUARIO"
                                    variant="outlined"
                                    select
                                    error={!!errors.tipo_rol_id}
                                    helperText={errors.tipo_rol_id || ''}
                                    value={formularioState.tipo_rol_id}
                                    onChange={handleTipoRol}
                                >
                                    <MenuItem value={1}>ADMINISTRADOR</MenuItem>
                                    <MenuItem value={2}>USUARIO</MenuItem>
                                    <MenuItem value={3}>DEPENDENCIA</MenuItem>
                                </TextField>
                            </Grid>
                            {formularioState.tipo_rol_id === 2 ?
                                <Grid item xs={12}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel >PERMISOS</InputLabel>
                                        <Select
                                            multiple
                                            value={permisosState}
                                            onChange={handleChangePermisos}
                                            error={!!errors.permisos}
                                            input={<OutlinedInput id="select-multiple-chip" label="PERMISOS" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {permisos.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.nombre}
                                                >
                                                    {item.nombre}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                <></>
                            }
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-correo"
                                    label="CORREO"
                                    variant="outlined"
                                    error={!!errors.email}
                                    helperText={errors.email || ''}
                                    value={formularioState.email}
                                    onChange={handleEmail}
                                    onKeyPress={(event) => {
                                        maxLengthLimitInput(event, 55, 'email');
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-telefono"
                                    label="TELÉFONO"
                                    variant="outlined"
                                    error={!!errors.telefono}
                                    helperText={errors.telefono || ''}
                                    onChange={handleTelefono}
                                    value={formularioState.telefono}
                                    onKeyPress={onlyNumber}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <h5 className='ms-4 mt-3'>DEPENDENCIA</h5>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={dependenciasState.data || []}
                                    getOptionLabel={(option) => option.dependencia}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{ borderRadius: "30px" }}
                                    value={dependenciasState.data.find(option => option.id === formularioState.dependencia_id) || null}
                                    onChange={(event, newValue) => handleDependencia(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="DEPENDENCIAS"
                                            error={!!errors.dependencia_id}
                                            helperText={errors.dependencia_id || ''}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton  sx={{backgroundColor: '#6A0F49'}} onClick={() => {
                                    crearUsuario();
                                }}>
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/* Modal para editar usuario */}
                <Modal
                    open={state.openModalEditar}
                    onClose={handleCloseEditar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            EDITAR USUARIO
                        </h3>
                        <hr />
                        <h5>DATOS GENERALES</h5>
                        <hr />
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE"
                                    variant="outlined"
                                    error={!!errors.nombre}
                                    helperText={errors.nombre || ''}
                                    value={state.usuarioSeleccionado.nombre}
                                    onChange={handleSeleccionadoNombre}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="TÍTULO"
                                    variant="outlined"
                                    select
                                    onChange={handleSeleccionadoTitulo}
                                    value={state.usuarioSeleccionado.cat_tipo_titulo_id}
                                >
                                    {titulosState.data && titulosState.data.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.tipo_titulo}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <h5 className='ms-4 mt-3'>USUARIO DEL SISTEMA</h5>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE DE USUARIO"
                                    variant="outlined"
                                    error={!!errors.usuario}
                                    helperText={errors.usuario || ''}
                                    value={state.usuarioSeleccionado.usuario}
                                    onChange={handleSeleccionadoUsuario}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-TipoUsuario"
                                    label="TIPO DE USUARIO"
                                    variant="outlined"
                                    select
                                    error={!!errors.tipo_rol_id}
                                    helperText={errors.tipo_rol_id || ''}
                                    value={state.usuarioSeleccionado.tipo_rol_id}
                                    onChange={handleSeleccionadoTipoRol}
                                >
                                    <MenuItem value={1}>ADMINISTRADOR</MenuItem>
                                    <MenuItem value={2}>USUARIO</MenuItem>
                                    <MenuItem value={3}>DEPENDENCIA</MenuItem>
                                </TextField>
                            </Grid>
                            {state.usuarioSeleccionado.tipo_rol_id === 2 ?
                                <Grid item xs={12}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel >PERMISOS</InputLabel>
                                        <Select
                                            multiple
                                            value={permisosState}
                                            onChange={handleSeleccionadoChange}
                                            error={!!errors.permisos}
                                            input={<OutlinedInput id="select-multiple-chip" label="PERMISOS" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {permisos.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.nombre}
                                                >
                                                    {item.nombre}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                <></>
                            }
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-correo"
                                    label="CORREO"
                                    variant="outlined"
                                    error={!!errors.email}
                                    helperText={errors.email || ''}
                                    value={state.usuarioSeleccionado.email}
                                    onChange={handleSeleccionadoEmail}
                                    onKeyPress={(event) => {
                                        maxLengthLimitInput(event, 55, 'email');
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    size='small'
                                    label="CONTRASEÑA"
                                    variant="outlined"
                                    type={state.showPassword ? 'text' : 'password'}
                                    error={!!errors.password_desencriptada}
                                    helperText={errors.password_desencriptada || ''}
                                    value={state.usuarioSeleccionado.password_desencriptada}
                                    onChange={handleSeleccionadoPass}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        sx: { borderRadius: 30 }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-telefono"
                                    label="TELÉFONO"
                                    variant="outlined"
                                    error={!!errors.telefono}
                                    helperText={errors.telefono || ''}
                                    onChange={handleSeleccionadoTelefono}
                                    value={state.usuarioSeleccionado.telefono}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>
                            <h5 className='ms-4 mt-3'>DEPENDENCIA</h5>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={dependenciasState.data || []}
                                    getOptionLabel={(option) => option.dependencia}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={dependenciasState.data.find(option => option.id === state.usuarioSeleccionado.dependencia_id) || null}
                                    onChange={(event, newValue) => handleSeleccionadoDependencia(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="DEPENDENCIAS"
                                            error={!!errors.dependencia_id}
                                            helperText={errors.dependencia_id || ''}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarUsuario();
                                }}>
                                    Editar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Eliminar */}
                <Modal
                    open={state.openModalEliminar}
                    onClose={handleCloseEliminar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style2}>
                        <h3 className='TitulosProgramas  mb-5'>
                            ¿Está Seguro de Eliminar al Usuario?
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={4}
                        >

                            <Grid item xs={12}>
                                <h4>
                                    {state.usuarioSeleccionado.nombre}
                                </h4>
                            </Grid>

                            <Grid margin="auto" item >
                                <ColorButton onClick={() => {
                                    eliminarUsuario();

                                }}>
                                    Si, Eliminar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

            </div>
        </>
    )
}





export default Usuarios;

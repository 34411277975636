import React, { useState, useEffect, useContext } from 'react';
import IconUI from '../components/iu/icon_ui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Tooltip } from '@mui/material';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField, MenuItem, FormGroup, FormLabel } from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { GrDocumentDownload } from 'react-icons/gr';
import { maxLengthLimitInput, onlyNumber } from '../utils/inputsRules';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';
import MUIDataTable from "mui-datatables";
import "dayjs/locale/es"
import { createTheme, ThemeProvider } from '@mui/material/styles';

const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        borderRadius: '30px !important',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: '#6A0F49 !important',
                        color: 'white'
                    },
                },
            },
        },
    });



const AudienciaUser = () => {



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        //border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 600,
        borderRadius: "40px",
        scrollbarWidth: "none",
    };

    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        //border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 'auto',
        borderRadius: "40px",
        scrollbarWidth: "none",
    };

    const style3 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '90%',
            sm: '80%',
            md: 1200,
        },
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: {
            xs: '90vh',
            sm: '80vh',
            md: '90%',
        },
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    //state del loading
    const [loader, setLoader] = useState({
        loading: true
    });

    const { showNotification } = useContext(NotificacionContext)

    // state del componente
    const [state, setState] = useState({
        municipios: [],
        audiencia: [],
        audienciaSeleccionado: {},
        openModalEditar: false,
        openModalEliminar: false,
        openDocumento: false,
        openObservaciones: false,
        observaciones: "",
    });

    const permiso = localStorage.getItem('EXPORTAR_AUDIENCIA');
    const eliminar = localStorage.getItem('BORRAR_ASUNTO');


    // state del Formulario
    const [formularioState, setFormulario] = useState({
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        ine: '',
        edad: '',
        calle: '',
        numero_ext: '',
        colonia: '',
        cp: '',
        telefono: '',
        municipio: '',
        localidad: '',
        estado: '',
        email: '',
        derechohabiente: '',
        asunto: '',
        observaciones: '',
        turnado_a: '',
        fecha_turno: '',
    });

    const [errors, setErrors] = useState({});

    //state del modal de agregar nuevo
    const [openModal, setOpenModal] = useState(false)

    const handleCloseModal = ()=>{
        setOpenModal(false);
        limpiarFormulario();
    }

    //abrir y cerrar modal de eliminar
    const openModalEliminar = (data) => {
        setState({ ...state, openModalEliminar: true, audienciaSeleccionado: data });
    }

    const handleCloseEliminar = () => {
        setState({
            ...state, openModalEliminar: false
        });
    }

    //abrir y cerrar modal de editar
    const openModalEditar = async (data) => {
      let localidades = await services({ method: 'POST', service: `catLocalidades/listaPorMunicipio`, body: { municipio_id: data.municipio_id } });
      if (localidades.status === 200) {
          setFormulario((formularioState) => ({ ...formularioState, municipio: data ? data.municipio_id : '', localidad: '' }))
      } else {
          showNotification('error', 'Ups...', localidades.data.message, null, 4)
      }
  setState({ ...state, openModalEditar: true, audienciaSeleccionado: data, localidades: localidades.data.data });//AQUI
    }

    const handleCloseEditar = () => {
        setState({
            ...state, openModalEditar: false
        });
    }

    //abrir y cerrar modal de observaciones
    const openModalObservaciones = (data) => {
        setState({ ...state, openObservaciones: true, observaciones: data });
    }

    const handleCloseObservaciones = () => {
        setState({ ...state, openObservaciones: false });
    }


    const handleCloseDocumento = () => {
        setState({ ...state, openDocumento: false });
    }

    //columnas y lógica de la tabla
    const columns = [
        {
            name: "usuario_registro",
            label: "REGISTRO",
            options: {
                filter: permiso ? true : false,
                sort: false,
                display: false,
                customBodyRenderLite: (dataIndex) => {
                    let registro = state.audiencia[dataIndex].usuario_registro;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{registro}</div>)
                }
            }
        },
        {
            name: "folio",
            label: "FOLIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let folio = state.audiencia[dataIndex].folio;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{folio}</div>)
                }
            }
        },
        {
            name: "fecha_creacion",
            label: "FECHA",
            options: {
                filter: true,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    let alta = dayjs(state.audiencia[dataIndex].fecha_creacion, 'DD-MM-YYYY');
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}> {alta.format('DD-MM-YYYY')} </div>)
                },
                filterType: 'custom',
                customFilterListOptions: {
                    render: (v) => {
                        if (v[0] && v[1] && state.FilterChecked) {
                            return [`FECHA INICIO: ${dayjs(v[0], 'DD-MM-YYYY').format('DD-MM-YYYY')}`, `FECHA FINAL: ${dayjs(v[1], 'DD-MM-YYYY').format('DD-MM-YYYY')}`];
                        } else if (v[0] && v[1] && !state.FilterChecked) {
                            return `FECHA INICIO: ${dayjs(v[0], 'DD-MM-YYYY').format('DD-MM-YYYY')}, FECHA FINAL: ${dayjs(v[1], 'DD-MM-YYYY').format('DD-MM-YYYY')}`;
                        } else if (v[0]) {
                            return `FECHA INICIO: ${dayjs(v[0], 'DD-MM-YYYY').format('DD-MM-YYYY')}`;
                        } else if (v[1]) {
                            return `FECHA FINAL: ${dayjs(v[1], 'DD-MM-YYYY').format('DD-MM-YYYY')}`;
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {

                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic: (fecha_creacion, filters) => {
                        const fechaMoment = dayjs(fecha_creacion, 'DD-MM-YYYY');

                        if (filters[0] && filters[1]) {
                            return (
                                fechaMoment.isBefore(dayjs(filters[0], 'DD-MM-YYYY')) ||
                                fechaMoment.isAfter(dayjs(filters[1], 'DD-MM-YYYY'))
                            );
                        } else if (filters[0]) {
                            return fechaMoment.isBefore(dayjs(filters[0], 'DD-MM-YYYY'));
                        } else if (filters[1]) {
                            return fechaMoment.isAfter(dayjs(filters[1], 'DD-MM-YYYY'));
                        }

                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <FormLabel className='mb-2' style={{ fontSize: '12px' }}>RANGO DE FECHAS</FormLabel>
                            <FormGroup row>

                                <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="INICIO"
                                        slotProps={{ textField: { size: 'small' } }}
                                        value={filterList[index][0] ? dayjs(filterList[index][0], 'DD-MM-YYYY') : null}
                                        views={['day', 'month', 'year']}
                                        onChange={event => {
                                            filterList[index][0] = dayjs(event).format('DD-MM-YYYY');
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '40%' }}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="FINAL"
                                        slotProps={{ textField: { size: 'small' } }}
                                        value={filterList[index][1] ? dayjs(filterList[index][1], 'DD-MM-YYYY') : null}
                                        views={['day', 'month', 'year']}
                                        onChange={event => {
                                            filterList[index][1] = dayjs(event).format('DD-MM-YYYY');
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '40%', marginLeft: '10px' }}
                                    />
                                </LocalizationProvider>
                            </FormGroup>
                        </div>
                    ),
                }
            }
        },
        {
            name: "ciudadano_audiencia",
            label: "NOMBRE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.audiencia[dataIndex].ciudadano_audiencia;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "telefono",
            label: "TELÉFONO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let telefono = state.audiencia[dataIndex].telefono;
                    if (telefono !== null) {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center">{telefono}</div>)
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex  justify-content-center align-items-center">-</div>)

                    }
                }
            }
        },
        {
            name: "municipio",
            label: "MUNICIPIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let municipio = state.audiencia[dataIndex].municipio;
                    if (municipio !== null) {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center">{municipio}</div>)
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex  justify-content-center align-items-center">-</div>)

                    }
                }
            }
        },
        {
            name: "asunto",
            label: "ASUNTO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let asunto = state.audiencia[dataIndex].asunto;
                    if (asunto !== null && asunto !== "") {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center cursor-pointer" onClick={() => { openModalObservaciones(state.audiencia[dataIndex].asunto) }}>{asunto.substring(0, 30)}...</div>)
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex  justify-content-center align-items-center">-</div>)

                    }
                }
            }
        },
        {
            name: "observaciones",
            label: "OBSERVACIONES",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let observaciones = state.audiencia[dataIndex].observaciones;
                    if (observaciones !== "" && observaciones !== null) {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center cursor-pointer" onClick={() => { openModalObservaciones(state.audiencia[dataIndex].observaciones) }}>{observaciones.substring(0, 30)}...</div>)
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center justify-content-center">-</div>)
                    }
                }
            }
        },
        {
            name: "turnado_a",
            label: "TURNADO A",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let turnado_a = state.audiencia[dataIndex].turnado_a;
                    if (turnado_a !== null) {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center">{turnado_a}</div>)
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex  justify-content-center align-items-center">-</div>)

                    }
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px' }} className="d-flex align-items-center">
                            <Tooltip title="EDITAR AUDIENCIA">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openModalEditar(state.audiencia[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                </div>
                            </Tooltip>
                            {eliminar ?
                                <Tooltip title="ELIMINAR AUDIENCIA">
                                    <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                        onClick={
                                            () => {
                                                openModalEliminar(state.audiencia[dataIndex])
                                            }}
                                    >
                                        <IconUI size={'20px'} color={'black'}><AiOutlineDelete /></IconUI>

                                    </div>
                                </Tooltip>
                                :
                                <></>
                            }
                            <Tooltip title="FICHA TÉCNICA">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            descargarDocumentos(state.audiencia[dataIndex].id)
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><GrDocumentDownload /></IconUI>

                                </div>
                            </Tooltip>


                        </div>

                    </>
                )
            }
        },

    ];

    const customSearch = (searchQuery, currentRow, columns) => {
        const normalizedSearchQuery = searchQuery.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        return currentRow.some((cell) => {
            const normalizedCell = String(cell).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            return normalizedCell.includes(normalizedSearchQuery);
        });
    };

    const options = {
        selectableRows: 'none',
        tableBodyHeight: 'auto',
        searchText: '',
        customSearch,
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no hay coincidencias en la búsqueda",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) => `Clasificar por ${column.label}`
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todo",
                title: "Filtros",
                reset: "Restaurar",
            },
            viewColumns: {
                title: "Ver columnas",
                titleAria: "Mostrar/Ocultar columnas en la tabla",
            },
            selectedRows: {
                text: "columna(s) selecciona(as)",
                delete: "Eliminar",
                deleteAria: "Eliminar filas seleccionadas",
            },
        },
        // responsive: 'standard',
        downloadOptions: {
            filename: 'TABLA DE AUDIENCIAS.csv',
            utf8WithBom: false,
            filterOptions: {
                useDisplayedColumnsOnly: false,
                useDisplayedRowsOnly: false,
            }
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            return "\uFEFF" + buildHead(columns) + buildBody(data);
        },

        download: permiso ? true : false,
        // filter:false,
        print: false,
        viewColumns: false,
    };

    // manejo del formulario nuevo

    const handleNombre = (e) => {
        setFormulario({ ...formularioState, nombre: e.target.value })
    }
    const handleApellidoPaterno = (e) => {
        setFormulario({ ...formularioState, apellido_paterno: e.target.value })
    }
    const handleApellidoMaterno = (e) => {
        setFormulario({ ...formularioState, apellido_materno: e.target.value })
    }
    const handleEdad = (e) => {
        setFormulario({ ...formularioState, edad: e.target.value })
    }
    const handleIne = (e) => {
        setFormulario({ ...formularioState, ine: e.target.value })
    }
    const handleTelefono = (e) => {
        setFormulario({ ...formularioState, telefono: e.target.value })
    }
    const handleEmail = (e) => {
        setFormulario({ ...formularioState, email: e.target.value })
    }
    const handleCalle = (e) => {
        setFormulario({ ...formularioState, calle: e.target.value })
    }
    const handleNumero = (e) => {
        setFormulario({ ...formularioState, numero_ext: e.target.value })
    }
    const handleColonia = (e) => {
        setFormulario({ ...formularioState, colonia: e.target.value })
    }
    const handleCp = (e) => {
        setFormulario({ ...formularioState, cp: e.target.value })
    }
    const handleEstado = (e) => {
        setFormulario({ ...formularioState, estado: e.target.value })
    }

    const handleMunicipio = async (e) => {
        let localidades = await services({ method: 'POST', service: `catLocalidades/listaPorMunicipio`, body: { municipio_id: e.target.value } });
        if (localidades.status === 200) {
            setFormulario({ ...formularioState, municipio: e.target.value })
            setState({ ...state, localidades: localidades.data.data })
        } else {
            showNotification('error', 'Ups...', localidades.data.message, null, 4)
        }
    }
    const handleLocalidad = (e) => {
        setFormulario({ ...formularioState, localidad: e.target.value })
    }

    const handleDerechoHabiente = (e) => {
        setFormulario({ ...formularioState, derechohabiente: e.target.value })
    }
    const handleAsunto = (e) => {
        setFormulario({ ...formularioState, asunto: e.target.value })
    }
    const handleObservaciones = (e) => {
        setFormulario({ ...formularioState, observaciones: e.target.value })
    }
    const handleTurnado = (e) => {
        setFormulario({ ...formularioState, turnado_a: e.target.value })
    }
    const handleFechaTurno = (e) => {
        const selectedDate = dayjs(e.$d);
        setFormulario({ ...formularioState, fecha_turno: selectedDate.format('YYYY-MM-DD') })
    }

    // manejo del formulario nuevo usuario

    // manejo de formulario editar usuario

    const handleSeleccionadoNombre = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, nombre: e.target.value } })
    }
    const handleSeleccionadoApellidoPaterno = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, apellido_paterno: e.target.value } })
    }
    const handleSeleccionadoApellidoMaterno = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, apellido_materno: e.target.value } })
    }
    const handleSeleccionadoEdad = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, edad: e.target.value } })
    }
    const handleSeleccionadoIne = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, ine: e.target.value } })
    }
    const handleSeleccionadoTelefono = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, telefono: e.target.value } })
    }
    const handleSeleccionadoEmail = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, email: e.target.value } })
    }
    const handleSeleccionadoCalle = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, calle: e.target.value } })
    }
    const handleSeleccionadoNumero = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, numero_ext: e.target.value } })
    }
    const handleSeleccionadoColonia = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, colonia: e.target.value } })
    }
    const handleSeleccionadoCp = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, cp: e.target.value } })
    }
    const handleSeleccionadoEstado = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, estado: e.target.value } })
    }
    const handleSeleccionadoMunicipio = async (e) => {
        let localidades = await services({ method: 'POST', service: `catLocalidades/listaPorMunicipio`, body: { municipio_id: e.target.value } });
        if (localidades.status === 200) {
            setState({ ...state, localidades: localidades.data.data, audienciaSeleccionado: { ...state.audienciaSeleccionado, municipio_id: e.target.value, localidad_id: null } })
        } else {
            showNotification('error', 'Ups...', localidades.data.message, null, 4)
        }
    }
    const handleSeleccionadoLocalidad = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, localidad_id: e.target.value } })
    }

    const handleSeleccionadoDerechoHabiente = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, derechohabiente: e.target.value } })
    }
    const handleSeleccionadoFechaTurno = (e) => {
        const selectedDate = dayjs(e.$d);
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, fecha_turno: selectedDate.format('YYYY-MM-DD') } })
    }
    const handleSeleccionadoAsunto = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, asunto: e.target.value } })
    }
    const handleSeleccionadoObservaciones = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, observaciones: e.target.value } })
    }
    const handleSeleccionadoTurnado = (e) => {
        setState({ ...state, audienciaSeleccionado: { ...state.audienciaSeleccionado, turnado_a: e.target.value } })
    }
    //manejo de formulario editar usuario

    // validaciones del formulario
    const validarFormulario = () => {
        let newErrors = {};
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // if (!formularioState.folio) { newErrors.folio = 'Este Campo es Requerido' }
        if (!formularioState.nombre) { newErrors.nombre = 'Este Campo es Requerido' }
        if (!formularioState.apellido_paterno) { newErrors.apellidoPaterno = 'Este Campo es Requerido' }
        // if (!formularioState.apellido_materno) { newErrors.apellidoMaterno = 'Este Campo es Requerido' }
        if (formularioState.telefono !== "" && formularioState.telefono.length < 10) { newErrors.telefono = 'Favor poner un número de 10 dígitos' }
        if (formularioState.ine !== "" && formularioState.ine.length < 13) { newErrors.ine = 'Favor poner un ine válido' }
        if (formularioState.cp !== "" && formularioState.cp.length < 5) { newErrors.cp = 'Favor poner un código postal válido' }
        if (formularioState.ine !== "" && formularioState.ine.length < 13) { newErrors.ine = 'Favor poner los 13 caracteres del ine' }
        if (formularioState.email !== "") {
            let valido = formularioState.email.match(regexEmail)
            if (!valido) {
                newErrors.email = 'Ingrese un email válido'
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    // validaciones del editar
    const validarEditar = () => {
        let newErrors = {};
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // if (!state.audienciaSeleccionado.folio) { newErrors.folio = 'Este Campo es Requerido' }
        if (!state.audienciaSeleccionado.nombre) { newErrors.nombre = 'Este Campo es Requerido' }
        if (!state.audienciaSeleccionado.apellido_paterno) { newErrors.apellidoPaterno = 'Este Campo es Requerido' }
        // if (!state.audienciaSeleccionado.apellido_materno) { newErrors.apellidoMaterno = 'Este Campo es Requerido' }
        if (state.audienciaSeleccionado.telefono !== null && state.audienciaSeleccionado.telefono?.length < 10) { newErrors.telefono = 'Favor poner un número de 10 dígitos' }
        if (state.audienciaSeleccionado.cp !== null && state.audienciaSeleccionado.cp?.length < 5) { newErrors.cp = 'Favor poner un código postal válido' }
        if (state.audienciaSeleccionado.ine !== "" && state.audienciaSeleccionado.ine?.length < 13) { newErrors.ine = 'Favor poner los 13 caracteres del ine' }
        if (state.audienciaSeleccionado.email !== null && state.audienciaSeleccionado.email !== "") {
            let valido = state.audienciaSeleccionado.email.match(regexEmail)
            if (!valido) {
                newErrors.email = 'Ingrese un email válido'
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }


    //Petición de Audiencia
    // eslint-disable-next-line
    const mostrarAudiencias = async () => {
        setLoader({ ...loader, loading: true })

        let audiencias = await services({ method: 'GET', service: `audiencias/listar`, body: null });
        let municipios = await services({ method: 'GET', service: `catMunicipios/listar`, body: null })


        if (audiencias.status === 200 && municipios.status === 200) {
            setState({ ...state, audiencia: audiencias.data.data, municipios: municipios.data.data })
            setLoader({ ...loader, loading: false })

        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups... Algo Ocurrió', 'Vuelva a intentarlo mas tarde ', null, 4);

        }
    }

    //limpiar formulario
    const limpiarFormulario = () => {
        setFormulario({
            ...formularioState,
            nombre: '',
            apellido_paterno: '',
            apellido_materno: '',
            edad:'',
            ine:'',
            calle: '',
            numero_ext: '',
            colonia: '',
            cp: '',
            telefono: '',
            municipio: '',
            localidad: '',
            estado: '',
            email: '',
            derechohabiente: '',
            asunto: '',
            observaciones: '',
            turnado_a: '',
            fecha_turno: '',
        })
    }

    //Crear Audiencia Nuevo
    const crearAudiencia = async () => {

        if (validarFormulario()) {
            setLoader({ ...loader, loading: true })
            const audiencia = await services({
                method: 'POST', service: 'audiencias/crear', body: {
                    nombre: formularioState.nombre,
                    apellido_paterno: formularioState.apellido_paterno,
                    apellido_materno: formularioState.apellido_materno,
                    edad: formularioState.edad,
                    ine: formularioState.ine,
                    calle: formularioState.calle,
                    numero_ext: formularioState.numero_ext,
                    colonia: formularioState.colonia,
                    cp: formularioState.cp,
                    telefono: formularioState.telefono,
                    municipio_id: formularioState.municipio,
                    localidad_id: formularioState.localidad,
                    estado: formularioState.estado,
                    email: formularioState.email,
                    derechohabiente: formularioState.derechohabiente,
                    asunto: formularioState.asunto,
                    observaciones: formularioState.observaciones,
                    turnado_a: formularioState.turnado_a,
                    fecha_turno: formularioState.fecha_turno,
                }
            })
            if (audiencia.status === 200) {
                let audiencias = await services({ method: 'GET', service: `audiencias/listar`, body: null })
                if (audiencias.status === 200) {
                    setState({ ...state, audiencia: audiencias.data.data })
                    showNotification('success', 'Audiencia', 'Se ha Agregado con Éxito', null, 4);
                    setOpenModal(false);
                    limpiarFormulario();
                    setLoader({ ...loader, loading: false })
                }
            } else {
                showNotification('error', 'Ups... Algo sucedió', 'Error al agregar la audiencia', null, 4);
                setOpenModal(false);
                setLoader({ ...loader, loading: false })
                limpiarFormulario();
            }
        }
        else {
            showNotification('error', 'ups...Error ', 'Por favor llena todos los campos', null, 4);
            setLoader({ ...loader, loading: false })
        }

    }

    // editar Usuario Nuevo
    const editarAudiencia = async () => {
        setLoader({ ...loader, loading: true })
        if (validarEditar()) {
            const audiencia = await services({
                method: 'POST', service: `audiencias/editar`, body: {
                    id: state.audienciaSeleccionado.id,
                    nombre: state.audienciaSeleccionado.nombre?.toUpperCase(),
                    apellido_paterno: state.audienciaSeleccionado.apellido_paterno?.toUpperCase(),
                    apellido_materno: state.audienciaSeleccionado.apellido_materno?.toUpperCase(),
                    edad: state.audienciaSeleccionado.edad,
                    ine: state.audienciaSeleccionado.ine,
                    calle: state.audienciaSeleccionado.calle?.toUpperCase(),
                    numero_ext: state.audienciaSeleccionado.numero_ext?.toUpperCase(),
                    colonia: state.audienciaSeleccionado.colonia?.toUpperCase(),
                    cp: state.audienciaSeleccionado.cp,
                    telefono: state.audienciaSeleccionado.telefono,
                    municipio_id: state.audienciaSeleccionado.municipio_id,
                    localidad_id: state.audienciaSeleccionado.localidad_id,
                    estado: state.audienciaSeleccionado.estado,
                    email: state.audienciaSeleccionado.email?.toUpperCase(),
                    derechohabiente: state.audienciaSeleccionado.derechohabiente,
                    asunto: state.audienciaSeleccionado.asunto?.toUpperCase(),
                    observaciones: state.audienciaSeleccionado.observaciones?.toUpperCase(),
                    turnado_a: state.audienciaSeleccionado.turnado_a?.toUpperCase(),
                    fecha_turno: state.audienciaSeleccionado.fecha_turno,
                }
            })
            if (audiencia.status === 200) {
                let audiencias = await services({ method: 'GET', service: `audiencias/listar`, body: null })
                if (audiencias.status === 200) {
                    setState({ ...state, audiencia: audiencias.data.data, openModalEditar: false })
                    showNotification('success', 'Audiencia', 'Editada con Éxito', null, 4);
                    setLoader({ ...loader, loading: false })
                } else {
                    showNotification('error', 'Ups... Algo Sucedió', 'Error al Editar Audiencia', null, 4);
                    setState({ ...state, openModalEditar: false })
                    setLoader({ ...loader, loading: false })
                }
            }
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Verifica', 'Los campos marcados', null, 4);
        }
    }

    const descargarDocumentos = async (id) => {
        setLoader({ ...loader, loading: true })
        let response = await services({ method: 'POST', service: `documentos/fichaAudiencia`, body: { id: id } });
        if (response.status === 200) {

            setLoader({ ...loader, loading: false })
            setState({ ...state, openDocumento: true, openImprimir: false, urlFrame: response.data.archivo })
        } else {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openImprimir: false })
            showNotification('error', 'Ups...Algo Sucedió', response.data.message, null, 4);
        }
    }

    // eliminarAudiencia
    const eliminarAudiencia = async () => {
        setLoader({ ...loader, loading: true })
        const audiencia = await services({ method: 'POST', service: `audiencias/eliminar`, body: { id: state.audienciaSeleccionado.id } })
        if (audiencia.status === 200) {
            let audiencias = await services({ method: 'GET', service: `audiencias/listar`, body: null })
            if (audiencias.status === 200) {
                setState({ ...state, audiencia: audiencias.data.data, openModalEliminar: false })
                showNotification('success', 'Audiencia', 'Eliminada con Éxito', null, 4);
                setLoader({ ...loader, loading: false })
            } else {
                setState({ ...state, openModalEliminar: false })
                setLoader({ ...loader, loading: false })
                showNotification('error', 'Ups... Algo sucedió', 'Error al Eliminar Audiencia', null, 4);
            }

        } else {
            setState({ ...state, openModalEliminar: false })
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups... Algo sucedió', 'Error al Eliminar Audiencia', null, 4);
        }
    }

    /* eslint-disable  */
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarAudiencias()
        }
        return () => {
            isMounted = false
        }
    }, [])
    /* eslint-disable  */

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>

                {loader.loading &&
                    <LoaderComponent />
                }
                <div className='container'>
                    <Grid sx={{ marginTop: 3 }} container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                        <Grid item >
                            <ColorButton startIcon={<AddCircleOutlineIcon />} onClick={() => { setOpenModal(true) }}>
                                Nuevo Registro
                            </ColorButton>
                        </Grid>
                    </Grid>
                </div>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid className='container   mt-3 mb-3' maxWidth="xl">
                        <ThemeProvider theme={getMuiTheme}>
                            <MUIDataTable
                                data={state.audiencia}
                                title={`TABLA DE AUDIENCIAS`}
                                columns={columns}
                                options={options}
                            />
                        </ThemeProvider>
                    </Grid>
                </Box>
                {/* Modal para crear ciudadano */}
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            NUEVA AUDIENCIA
                        </h3>
                        <hr />
                        <h5>DATOS GENERALES</h5>
                        <hr />
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >


                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE"
                                    variant="outlined"
                                    error={!!errors.nombre}
                                    helperText={errors.nombre || ''}
                                    value={formularioState.nombre}
                                    onChange={handleNombre}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-apellidoP"
                                    label="APELLIDO PATERNO"
                                    variant="outlined"
                                    error={!!errors.apellidoPaterno}
                                    helperText={errors.apellidoPaterno || ''}
                                    value={formularioState.apellido_paterno}
                                    onChange={handleApellidoPaterno}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-apellidoM"
                                    label="APELLIDO MATERNO"
                                    variant="outlined"
                                    error={!!errors.apellidoMaterno}
                                    helperText={errors.apellidoMaterno || ''}
                                    value={formularioState.apellido_materno}
                                    onChange={handleApellidoMaterno}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-INE"
                                    label="EDAD"
                                    variant="outlined"
                                    error={!!errors.edad}
                                    helperText={errors.edad || ''}
                                    onChange={handleEdad}
                                    value={formularioState.edad}
                                    inputProps={{ maxLength: 2 }}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-INE"
                                    label="INE"
                                    variant="outlined"
                                    error={!!errors.ine}
                                    helperText={errors.ine || ''}
                                    onChange={handleIne}
                                    value={formularioState.ine}
                                    inputProps={{ maxLength: 13 }}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-TipoUsuario"
                                    label="DERECHOHABIENTE"
                                    variant="outlined"
                                    select
                                    onChange={handleDerechoHabiente}
                                    value={formularioState.derechohabiente}
                                >
                                    <MenuItem value={'IMSS'}>IMSS</MenuItem>
                                    <MenuItem value={'ISSSTE'}>ISSSTE</MenuItem>

                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-telefono"
                                    label="NÚMERO TELEFÓNICO"
                                    variant="outlined"
                                    error={!!errors.telefono}
                                    helperText={errors.telefono || ''}
                                    onChange={handleTelefono}
                                    value={formularioState.telefono}
                                    onKeyPress={onlyNumber}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-correo"
                                    label="CORREO ELECTRÓNICO"
                                    variant="outlined"
                                    value={formularioState.email}
                                    error={errors.email}
                                    helperText={errors.email || ''}
                                    onChange={handleEmail}
                                    onKeyPress={(event) => {
                                        maxLengthLimitInput(event, 55, 'email');
                                    }}
                                />
                            </Grid>
                            <hr />
                            <hr />
                            <h5 className='ms-4 mt-3'>DIRECCIÓN</h5>
                            <hr />
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-calle"
                                    label="CALLE"
                                    variant="outlined"
                                    error={errors.calle}
                                    helperText={errors.calle || ''}
                                    value={formularioState.calle}
                                    onChange={handleCalle}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-numero"
                                    label="NÚMERO"
                                    variant="outlined"
                                    error={errors.numero}
                                    helperText={errors.numero || ''}
                                    value={formularioState.numero}
                                    onChange={handleNumero}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-colonia"
                                    label="COLONIA"
                                    variant="outlined"
                                    error={errors.colonia}
                                    helperText={errors.colonia || ''}
                                    value={formularioState.colonia}
                                    onChange={handleColonia}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-cp"
                                    label="C.P."
                                    variant="outlined"
                                    error={errors.cp}
                                    helperText={errors.cp || ''}
                                    value={formularioState.cp}
                                    onChange={handleCp}
                                    inputProps={{ maxLength: 5 }}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-localidad"
                                    label="ENTIDAD FEDERATIVA"
                                    variant="outlined"
                                    error={errors.estado}
                                    helperText={errors.estado || ''}
                                    value={formularioState.estado}
                                    onChange={handleEstado}
                                    select
                                >
                                    <MenuItem value={"AGUASCALIENTES"}>AGUASCALIENTES</MenuItem>
                                    <MenuItem value={"BAJA CALIFORNIA"}>BAJA CALIFORNIA</MenuItem>
                                    <MenuItem value={"BAJA CALIFORNIA SUR"}>BAJA CALIFORNIA SUR</MenuItem>
                                    <MenuItem value={"CAMPECHE"}>CAMPECHE</MenuItem>
                                    <MenuItem value={"CHIAPAS"}>CHIAPAS</MenuItem>
                                    <MenuItem value={"CHIHUAHUA"}>CHIHUAHUA</MenuItem>
                                    <MenuItem value={"CIUDAD DE MÉXICO"}>CIUDAD DE MÉXICO</MenuItem>
                                    <MenuItem value={"COAHUILA DE ZARAGOZA"}>COAHUILA DE ZARAGOZA</MenuItem>
                                    <MenuItem value={"COLIMA"}>COLIMA</MenuItem>
                                    <MenuItem value={"DURANGO"}>DURANGO</MenuItem>
                                    <MenuItem value={"GUANAJUATO"}>GUANAJUATO</MenuItem>
                                    <MenuItem value={"GUERRERO"}>GUERRERO</MenuItem>
                                    <MenuItem value={"HIDALGO"}>HIDALGO</MenuItem>
                                    <MenuItem value={"JALISCO"}>JALISCO</MenuItem>
                                    <MenuItem value={"ESTADO DE MÉXICO"}>ESTADO DE MÉXICO</MenuItem>
                                    <MenuItem value={"MICHOACÁN"}>MICHOACÁN</MenuItem>
                                    <MenuItem value={"MORELOS"}>MORELOS</MenuItem>
                                    <MenuItem value={"NAYARIT"}>NAYARIT</MenuItem>
                                    <MenuItem value={"NUEVO LEÓN"}>NUEVO LEÓN</MenuItem>
                                    <MenuItem value={"OAXACA"}>OAXACA</MenuItem>
                                    <MenuItem value={"PUEBLA"}>PUEBLA</MenuItem>
                                    <MenuItem value={"QUERÉTARO"}>QUERÉTARO</MenuItem>
                                    <MenuItem value={"QUINTANA ROO"}>QUINTANA ROO</MenuItem>
                                    <MenuItem value={"SAN LUIS POTOSÍ"}>SAN LUIS POTOSÍ</MenuItem>
                                    <MenuItem value={"SINALOA"}>SINALOA</MenuItem>
                                    <MenuItem value={"SONORA"}>SONORA</MenuItem>
                                    <MenuItem value={"TABASCO"}>TABASCO</MenuItem>
                                    <MenuItem value={"TAMAULIPAS"}>TAMAULIPAS</MenuItem>
                                    <MenuItem value={"TLAXCALA"}>TLAXCALA</MenuItem>
                                    <MenuItem value={"VERACRUZ"}>VERACRUZ</MenuItem>
                                    <MenuItem value={"YUCATÁN"}>YUCATÁN</MenuItem>
                                    <MenuItem value={"ZACATECAS"}>ZACATECAS</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="MUNICIPIO"
                                    variant="outlined"
                                    select
                                    error={!!errors.municipio}
                                    helperText={errors.municipio || ''}
                                    onChange={handleMunicipio}
                                    value={formularioState.municipio}
                                >
                                    {state.municipios && state.municipios.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.municipio}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="LOCALIDAD"
                                    variant="outlined"
                                    select
                                    error={!!errors.localidad}
                                    helperText={errors.localidad || ''}
                                    onChange={handleLocalidad}
                                    value={formularioState.localidad}
                                >
                                    {state.localidades?.length > 0 ?
                                        state.localidades.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.localidad}
                                            </MenuItem>
                                        ))
                                        :
                                        <MenuItem key={0} value={null}>
                                            SIN LOCALIDADES
                                        </MenuItem>
                                    }
                                </TextField>
                            </Grid>
                            <hr />
                            <hr />
                            <h5 className='ms-4 mt-3'>DATOS AUDIENCIA</h5>
                            <hr />
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-localidad"
                                    label="TURNADO A"
                                    variant="outlined"
                                    value={formularioState.turnado_a}
                                    onChange={handleTurnado}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="FECHA DE TURNO"
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{ width: 1 }}
                                        // slotProps={{ textField: { helperText: 'Please fill this field' } }}
                                        format="DD/MM/YYYY"
                                        views={['year', 'month', 'day']}
                                        onChange={handleFechaTurno}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-localidad"
                                    label="ASUNTO"
                                    variant="outlined"
                                    value={formularioState.asunto}
                                    onChange={handleAsunto}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-localidad"
                                    label="OBSERVACIONES"
                                    variant="outlined"
                                    value={formularioState.observaciones}
                                    onChange={handleObservaciones}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    crearAudiencia();
                                }}>
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para editar audiencia */}
                <Modal
                    open={state.openModalEditar}
                    onClose={handleCloseEditar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            EDITAR AUDIENCIA
                        </h3>
                        <hr />
                        <h5>DATOS GENERALES</h5>
                        <hr />
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE"
                                    variant="outlined"
                                    error={!!errors.nombre}
                                    helperText={errors.nombre || ''}
                                    value={state.audienciaSeleccionado.nombre}
                                    onChange={handleSeleccionadoNombre}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-apellidoP"
                                    label="APELLIDO PATERNO"
                                    variant="outlined"
                                    error={!!errors.apellidoPaterno}
                                    helperText={errors.apellidoPaterno || ''}
                                    value={state.audienciaSeleccionado.apellido_paterno}
                                    onChange={handleSeleccionadoApellidoPaterno}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-apellidoM"
                                    label="APELLIDO MATERNO"
                                    variant="outlined"
                                    error={!!errors.apellidoMaterno}
                                    helperText={errors.apellidoMaterno || ''}
                                    value={state.audienciaSeleccionado.apellido_materno}
                                    onChange={handleSeleccionadoApellidoMaterno}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-INE"
                                    label="EDAD"
                                    variant="outlined"
                                    error={!!errors.edad}
                                    helperText={errors.edad || ''}
                                    onChange={handleSeleccionadoEdad}
                                    value={state.audienciaSeleccionado.edad}
                                    inputProps={{ maxLength: 2 }}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-INE"
                                    label="INE"
                                    variant="outlined"
                                    error={!!errors.ine}
                                    helperText={errors.ine || ''}
                                    onChange={handleSeleccionadoIne}
                                    value={state.audienciaSeleccionado.ine}
                                    inputProps={{ maxLength: 13 }}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-TipoUsuario"
                                    label="DERECHOHABIENTE"
                                    variant="outlined"
                                    select
                                    error={!!errors.tipo}
                                    helperText={errors.tipo || ''}
                                    onChange={handleSeleccionadoDerechoHabiente}
                                    value={state.audienciaSeleccionado.derechohabiente}
                                >
                                    <MenuItem value={'IMSS'}>IMSS</MenuItem>
                                    <MenuItem value={'ISSSTE'}>ISSSTE</MenuItem>

                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-telefono"
                                    label="NÚMERO TELEFÓNICO"
                                    variant="outlined"
                                    error={!!errors.telefono}
                                    helperText={errors.telefono || ''}
                                    onChange={handleSeleccionadoTelefono}
                                    value={state.audienciaSeleccionado.telefono !== null ? state.audienciaSeleccionado.telefono : ""}
                                    onKeyPress={onlyNumber}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-correo"
                                    label="CORREO ELECTRÓNICO"
                                    variant="outlined"
                                    value={state.audienciaSeleccionado.email !== null ? state.audienciaSeleccionado.email : ""}
                                    error={errors.email}
                                    helperText={errors.email || ''}
                                    onChange={handleSeleccionadoEmail}
                                    onKeyPress={(event) => {
                                        maxLengthLimitInput(event, 55, 'email');
                                    }}
                                />
                            </Grid>
                            <hr />
                            <hr />
                            <h5 className='ms-4 mt-3'>DIRECCIÓN</h5>
                            <hr />
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-calle"
                                    label="CALLE"
                                    variant="outlined"
                                    error={errors.calle}
                                    helperText={errors.calle || ''}
                                    value={state.audienciaSeleccionado.calle}
                                    onChange={handleSeleccionadoCalle}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-numero"
                                    label="NÚMERO"
                                    variant="outlined"
                                    error={errors.numero}
                                    helperText={errors.numero || ''}
                                    value={state.audienciaSeleccionado.numero_ext}
                                    onChange={handleSeleccionadoNumero}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-colonia"
                                    label="COLONIA"
                                    variant="outlined"
                                    error={errors.colonia}
                                    helperText={errors.colonia || ''}
                                    value={state.audienciaSeleccionado.colonia}
                                    onChange={handleSeleccionadoColonia}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-cp"
                                    label="C.P."
                                    variant="outlined"
                                    error={errors.cp}
                                    helperText={errors.cp || ''}
                                    value={state.audienciaSeleccionado.cp !== null ? state.audienciaSeleccionado.cp : ""}
                                    onChange={handleSeleccionadoCp}
                                    inputProps={{ maxLength: 5 }}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-localidad"
                                    label="ENTIDAD FEDERATIVA"
                                    variant="outlined"
                                    select
                                    error={errors.estado}
                                    helperText={errors.estado || ''}
                                    value={state.audienciaSeleccionado.estado}
                                    onChange={handleSeleccionadoEstado}
                                >
                                    <MenuItem value={"AGUASCALIENTES"}>AGUASCALIENTES</MenuItem>
                                    <MenuItem value={"BAJA CALIFORNIA"}>BAJA CALIFORNIA</MenuItem>
                                    <MenuItem value={"BAJA CALIFORNIA SUR"}>BAJA CALIFORNIA SUR</MenuItem>
                                    <MenuItem value={"CAMPECHE"}>CAMPECHE</MenuItem>
                                    <MenuItem value={"CHIAPAS"}>CHIAPAS</MenuItem>
                                    <MenuItem value={"CHIHUAHUA"}>CHIHUAHUA</MenuItem>
                                    <MenuItem value={"CIUDAD DE MÉXICO"}>CIUDAD DE MÉXICO</MenuItem>
                                    <MenuItem value={"COAHUILA DE ZARAGOZA"}>COAHUILA DE ZARAGOZA</MenuItem>
                                    <MenuItem value={"COLIMA"}>COLIMA</MenuItem>
                                    <MenuItem value={"DURANGO"}>DURANGO</MenuItem>
                                    <MenuItem value={"GUANAJUATO"}>GUANAJUATO</MenuItem>
                                    <MenuItem value={"GUERRERO"}>GUERRERO</MenuItem>
                                    <MenuItem value={"HIDALGO"}>HIDALGO</MenuItem>
                                    <MenuItem value={"JALISCO"}>JALISCO</MenuItem>
                                    <MenuItem value={"ESTADO DE MÉXICO"}>ESTADO DE MÉXICO</MenuItem>
                                    <MenuItem value={"MICHOACÁN"}>MICHOACÁN</MenuItem>
                                    <MenuItem value={"MORELOS"}>MORELOS</MenuItem>
                                    <MenuItem value={"NAYARIT"}>NAYARIT</MenuItem>
                                    <MenuItem value={"NUEVO LEÓN"}>NUEVO LEÓN</MenuItem>
                                    <MenuItem value={"OAXACA"}>OAXACA</MenuItem>
                                    <MenuItem value={"PUEBLA"}>PUEBLA</MenuItem>
                                    <MenuItem value={"QUERÉTARO"}>QUERÉTARO</MenuItem>
                                    <MenuItem value={"QUINTANA ROO"}>QUINTANA ROO</MenuItem>
                                    <MenuItem value={"SAN LUIS POTOSÍ"}>SAN LUIS POTOSÍ</MenuItem>
                                    <MenuItem value={"SINALOA"}>SINALOA</MenuItem>
                                    <MenuItem value={"SONORA"}>SONORA</MenuItem>
                                    <MenuItem value={"TABASCO"}>TABASCO</MenuItem>
                                    <MenuItem value={"TAMAULIPAS"}>TAMAULIPAS</MenuItem>
                                    <MenuItem value={"TLAXCALA"}>TLAXCALA</MenuItem>
                                    <MenuItem value={"VERACRUZ "}>VERACRUZ</MenuItem>
                                    <MenuItem value={"YUCATÁN"}>YUCATÁN</MenuItem>
                                    <MenuItem value={"ZACATECAS"}>ZACATECAS</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="MUNICIPIO"
                                    variant="outlined"
                                    select
                                    error={!!errors.municipio}
                                    helperText={errors.municipio || ''}
                                    onChange={handleSeleccionadoMunicipio}
                                    value={state.audienciaSeleccionado.municipio_id}
                                >
                                    {state.municipios && state.municipios.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.municipio}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="LOCALIDAD"
                                    variant="outlined"
                                    select
                                    error={!!errors.localidad}
                                    helperText={errors.localidad || ''}
                                    onChange={handleSeleccionadoLocalidad}
                                    value={state.audienciaSeleccionado.localidad_id}
                                >
                                    {state.localidades?.length > 0 ?
                                        state.localidades.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.localidad}
                                            </MenuItem>
                                        ))
                                        :
                                        <MenuItem key={0} value={null}>
                                            SIN LOCALIDADES
                                        </MenuItem>
                                    }
                                </TextField>
                            </Grid>
                            <hr />
                            <hr />
                            <h5 className='ms-4 mt-3'>DATOS AUDIENCIA</h5>
                            <hr />
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-localidad"
                                    label="TURNADO A"
                                    variant="outlined"
                                    value={state.audienciaSeleccionado.turnado_a}
                                    onChange={handleSeleccionadoTurnado}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="FECHA DE TURNO"
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{ width: 1 }}
                                        value={state.audienciaSeleccionado.fecha_turno !== null ? dayjs(state.audienciaSeleccionado.fecha_turno) : ""}
                                        format="DD/MM/YYYY"
                                        views={['year', 'month', 'day']}
                                        onChange={handleSeleccionadoFechaTurno}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-localidad"
                                    label="ASUNTO"
                                    variant="outlined"
                                    value={state.audienciaSeleccionado.asunto}
                                    onChange={handleSeleccionadoAsunto}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-localidad"
                                    label="OBSERVACIONES"
                                    variant="outlined"
                                    value={state.audienciaSeleccionado.observaciones}
                                    onChange={handleSeleccionadoObservaciones}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarAudiencia();
                                }}>
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para ver documento */}
                <Modal
                    open={state.openDocumento}
                    onClose={() => handleCloseDocumento()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style3}>
                        <p className="text-center">Documento</p>
                        <iframe style={{ width: "100%", height: "100%" }} id="frameAceptacion" title="DOCUMENTO" src={`data:application/pdf;base64,${state.urlFrame}`} className="preview" ></iframe>
                        <Button sx={{ mt: 2 }} onClick={() => handleCloseDocumento()} >Cerrar</Button>
                    </Box>
                </Modal>
                {/*Modal para Eliminar */}
                <Modal
                    open={state.openModalEliminar}
                    onClose={handleCloseEliminar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style2}>
                        <h3 className='TitulosProgramas  mb-5'>
                            ¿ESTÁ SEGURO DE ELIMINAR LA AUDIENCIA?
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={4}
                        >

                            <Grid item xs={12}>
                                <h4>
                                    {state.audienciaSeleccionado.folio}
                                </h4>
                            </Grid>

                            <Grid margin="auto" item >
                                <ColorButton onClick={() => {
                                    eliminarAudiencia()

                                }}>
                                    Eliminar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para ver observaciones */}
                <Modal
                    open={state.openObservaciones}
                    onClose={() => handleCloseObservaciones()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box className='contenedorModalUrs' sx={style2} >
                        <Grid
                            container
                            columns={12}
                            spacing={2}
                        >
                            <Grid item xs={12} >
                                <p className='text-break'>{state.observaciones}</p>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

            </div>
        </>
    )
}





export default AudienciaUser;
